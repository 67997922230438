import { toast } from 'react-toastify';
import axios from 'axios'
import { GET_ERRORS, BEFORE_GAME, GET_GAMES, GET_GAME, GET_FEATURED_GAMES, GET_POPULAR_GAMES, GET_NEW_GAMES, GET_GENRES, GET_GENRE_GAMES, GET_GENRE } from '../../redux/types';
import { emptyError } from '../../redux/shared/error/error.action';
import { ENV } from '../../config/config';

export const beforeGames = () => {
    return {
        type: BEFORE_GAME
    }
}

export const getGameDetail = (Id) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}games/${Id}`;
    fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
        },
    }).then(res => res.json()).then(data => {
        if (data.success) {
            // toast.success(data.message)
            dispatch({
                type: GET_GAME,
                payload: data
            })
        } else {
            // toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data.data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            // if (data.message)
            // toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const getFeaturedGames = () => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}games/get-featured-games`;
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
        },
    }).then(res => res.json()).then(data => {
        if (data.success) {
            // toast.success(data.message)
            dispatch({
                type: GET_FEATURED_GAMES,
                payload: data.games
            })
        } else {
            // toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data.data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            // if (data.message)
            // toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const getGames = (qs = '', body = {}) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}games/get-games`;
    if (qs)
        url += `?${qs}`
    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            // 'x-access-token': localStorage.getItem('accessToken'),

        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data.success) {
            // toast.success(data.message)
            dispatch({
                type: GET_GAMES,
                payload: data
            })
        } else {
            // toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data.data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            // if (data.message)
            // toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};
// Top 10 Games which have been played 
export const getPopularGames = () => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}games/get-popular-games`;
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
        },
    }).then(res => res.json()).then(data => {
        if (data.success) {
            // toast.success(data.message)
            dispatch({
                type: GET_POPULAR_GAMES,
                payload: data.games
            })
        } else {
            // toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data.data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            // if (data.message)
            // toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};
export const getNewGames = () => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}games/get-new-games`;
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
        },
    }).then(res => res.json()).then(data => {
        if (data.success) {
            // toast.success(data.message)
            dispatch({
                type: GET_NEW_GAMES,
                payload: data.games
            })
        } else {
            // toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data.data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            // if (data.message)
            // toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};
export const getGenres = (qs) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}games/get-genres`;

    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
        },
    }).then(res => res.json()).then(data => {
        if (data.success) {
            // toast.success(data.message)
            dispatch({
                type: GET_GENRES,
                payload: data.data
            })
        } else {
            // toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data.data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            // if (data.message)
            // toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const getGenre = (Id) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}games/get-genre-game/${Id}`;
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
        },
    }).then(res => res.json()).then(data => {
        if (data.success) {
            // toast.success(data.message)
            dispatch({
                type: GET_GENRE,
                payload: data.genre
            })
        } else {
            // toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data.data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            // if (data.message)
            // toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
}

export const getGenreGames = (genre) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}games/get-genre-games/${genre}`;
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
        },
    }).then(res => res.json()).then(data => {
        if (data.success) {
            // toast.success(data.message)
            dispatch({
                type: GET_GENRE_GAMES,
                payload: data.games
            })
        } else {
            // toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data.data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            // if (data.message)
            // toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};


export const getAllDepdoyment = (Id,toastCheck, callback) => dispatch => {
    toast.dismiss()
    dispatch(emptyError());
    let url = `${ENV.url}games/get-depolyment/${Id}`;
    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            // 'x-access-token': localStorage.getItem('dev-accessToken'),
            // 'user-platform': 1
        }
    })
        .then(res => res.json()).then(data => {
            if (data.success) {
                if(toastCheck){
                    toast.success(data.message)
                }
                callback(data.data)
            } else {
                dispatch({ type: GET_ERRORS, payload: data })

            }
        })

}

