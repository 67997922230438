import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Images } from "../../../assets/asset";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { ENV } from "../../../config/config";
import { connect } from 'react-redux'
import "./footer.css"
import { getCms, getFooter, beforeFooter } from './footer.action'
import FullPageLoader from "../../../components/FullPageLoader/FullPageLoader";
function Footer(props) {

    const [footer, setfooter] = useState({})
    // const [loader, setLoader] = useState(true)
    const [cms, setcms] = useState([])

    useEffect(() => {
        props.getFooter()
        props.getCms()
    }, [])



    useEffect(() => {
        if (props.footer.getfooter) {
            const { footer } = props?.footer
            // setLoader(false)
            setfooter(footer)
        }
    }, [props.footer.getfooter])


    useEffect(() => {
        if (props.footer.getcms) {
            const { cms } = props?.footer
            setcms(cms)
        }
    }, [props.footer.getcms])



    return (
        <>

            {/* loader ?
                    <FullPageLoader />
                    : */}
            <footer id="footer-design">
                <div className="top-footer">
                    <Container fluid className=" custom-container">
                        <Row>
                            <Col lg={3} className="d-flex justify-content-lg-center">
                                <div>
                                    <div className="footer-logo">
                                        <strong>
                                            <Link to="/">
                                                <img src={Images.footerlogo} className="img-fluid path-top " alt="img" />
                                            </Link>
                                        </strong>
                                    </div>
                                    <div className="footer-content">
                                        <p>{footer?.desc}</p>
                                    </div>
                                    <div className="social-icons">
                                        {
                                            footer?.instagram &&
                                            <a className="instagram-bg" href={footer?.instagram} target="_blank">
                                                <FontAwesomeIcon icon={faInstagram} />
                                            </a>
                                        }
                                        {
                                            footer?.twitter &&
                                            <a className="faTwitter-bg" href={footer?.twitter} target="_blank">
                                                <FontAwesomeIcon icon={faXTwitter} />
                                            </a>
                                        }
                                        {
                                            footer?.discord &&
                                            <a className="discord-bg" href={footer?.discord} target="_blank">
                                                <FontAwesomeIcon icon={faDiscord} />
                                            </a>
                                        }
                                        {
                                            footer?.telegram &&
                                            <a className="telegram-bg" href={footer?.telegram} target="_blank">
                                                <FontAwesomeIcon icon={faTelegram} />
                                            </a>
                                        }
                                    </div>
                                </div>
                            </Col>
                            <Col lg={2} className="d-flex justify-content-lg-center">
                                <div className="footer-linking contact-list">
                                    <ul>
                                        <li className="footer-heading">Developers</li>
                                        <li>
                                            <a href={`${ENV.deskillzDeveloperSiteUrl}/login`} target="_blank">Developer Console Login</a>
                                        </li>
                                        <li>
                                            <Link to="/">Documentation</Link>
                                        </li>
                                        <li>
                                            <Link to="/developers/faqs">Developer FAQ</Link>
                                        </li>
                                        <li>
                                            <a href={`${ENV.deskillzDeveloperSiteUrl}/dashboard/support`} target="_blank">Support</a>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg={2} className="d-flex justify-content-lg-center">
                                <div className="d-flex flex-column footer-linking contact-list">
                                    <ul>
                                        <li className="footer-heading">Players</li>
                                        <li>
                                            <Link to="/players">Deskillz Games</Link>
                                        </li>
                                        <li>
                                            <Link to="/players/faqs">Player FAQ</Link>
                                        </li>
                                        {/* <li>
                                            <a href={`${ENV.deskillzPlayerSiteUrl}/support`} target="_blank">Player Support</a>
                                        </li> */}
                                    </ul>
                                </div>
                            </Col>
                            <Col lg={2} className="d-flex jjustify-content-lg-center">
                                <div className="d-flex flex-column footer-linking contact-list">
                                    <ul>
                                        <li className="footer-heading">Company</li>
                                        <li>
                                            <Link to="/about">About Us</Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">Contact Us</Link>
                                        </li>
                                        <li>
                                            <Link to="/whitepaper" target="_blank">White Paper</Link>
                                        </li>
                                        {/* <li>
                                        <Link to="/contact">Legal </Link>
                                    </li> */}
                                    </ul>
                                </div>
                            </Col>

                            <Col lg={3} className="d-flex jjustify-content-lg-center">
                                <div className="d-flex flex-column footer-linking contact-list">
                                    <ul>
                                        {/* <li className="footer-heading">CMS</li> */}
                                        <li className="footer-heading">News</li>
                                        {
                                            cms && cms.map((c) => {
                                                return (<li>
                                                    <Link to={`/cms/${c.slug}`}>{c.title}</Link>
                                                </li>)
                                            })
                                        }


                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="bottom-footer">
                    <p>Copyright {new Date().getFullYear()} © Deskillz | All Rights Reserved (Designed by Deskillz (Pvt) Ltd.)</p>
                </div>
            </footer>

        </>

    );
}


const mapStateToProps = state => ({
    footer: state.footer,
    error: state.error,
});

export default connect(mapStateToProps, { getFooter, beforeFooter, getCms })(Footer);
