import {Container, Row, Col} from "react-bootstrap";
import Lottie from "react-lottie-player";
import lottieJson2 from "../../../assets/41442.json";
import lottieJson3 from "../../../assets/lf30_editor_st8fv4maa.json";
import lottieJson4 from "../../../assets/22591.json";
import lottieJson5 from "../../../assets/76222.json";
import "./GameOwner.css";


function GameOwner() {
    return (
        <>
            <section className="game-dev-owner">
                <Container fluid className="custom-container">
                    <div className="d-flex justify-content-center align-items-center flex-column">
                        <h3 className="common-heading">Game Devs/Owners</h3>
                        <p className="paragraph-text-2">
                            {/* <span className="red-text">The Future of sports is electronic,</span> Ready to level up your game? */}
                        </p>
                    </div>
                    <div className="system-integration-detail">
                        <Row>
                            <Col lg="3" md="6" className="d-flex  flex-column justify-content-center align-items-center">
                                <div className="border-items-around">
                                    <div className="test-inner user-box">
                                        <Lottie loop animationData={lottieJson3} play style={{width: 135, height: 135, speed: 1}} />
                                    </div>
                                </div>
                                <div className="description-services">
                                    <h6>Simple registration </h6>
                                </div>
                            </Col>
                            <Col lg="3" md="6" className="d-flex  flex-column justify-content-center align-items-center">
                                <div className="border-items-around">
                                    <div className="test-inner">
                                        <Lottie loop animationData={lottieJson4} play style={{width: 175, height: 175, speed: 1}} />
                                    </div>
                                </div>
                                <div className="description-services">
                                    <h6>Download SDK, iOS/Android </h6>
                                </div>
                            </Col>
                            <Col lg="3" md="6" className="d-flex  flex-column justify-content-center align-items-center">
                                <div className="border-items-around">
                                    <div className="test-inner">
                                        <Lottie loop animationData={lottieJson2} play style={{width: 165, height: 165, speed: 1}} />
                                    </div>
                                </div>
                                <div className="description-services">
                                    <h6>Set up your game </h6>
                                </div>
                            </Col>
                            <Col lg="3"  md="6"  className="d-flex  flex-column justify-content-center align-items-center">
                                <div className="border-items-around">
                                    <div className="test-inner">
                                        <Lottie loop animationData={lottieJson5} play style={{width: 175, height: 175, speed: 1}} />
                                    </div>
                                </div>
                                <div className="description-services">
                                    <h6>Collect profits</h6>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    );
}

export default GameOwner;
