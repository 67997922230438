import { Container, Row, Col } from "react-bootstrap";
import { Images } from "../../../assets/asset";
import "./DashboardGaming.css";


function GamingDashboard() {
    return (
        <>
            <section className="gaming-dashboard">
                <Container fluid className="custom-container">
                    <div className="d-flex justify-content-center align-items-center flex-column">
                        <h3 className="common-heading">Your Gaming Dashboard</h3>
                        <p className="paragraph-text-2">Monitor all your stats and profits in real time. Cha-ching!!</p>
                    </div>
                    <div className="d-flex dashboard-image-container">
                        <img src={Images.gamingdashboard} className="img-fluid" alt="Decentralized Platform for Gamers" />
                    </div>
                </Container>
            </section>
        </>
    );
}

export default GamingDashboard;
