
import { BEFORE_CMS_PAGE, GET_CMS, GET_CMS_PAGE, GET_CMS_PAGES } from '../../redux/types'
const initialState = {
    cms: null,
    pagination: null,
    getcms: false,
    getCmsPagesAuth: false,
}

const CmsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CMS_PAGE:
            return {
                ...state,
                cms: action.payload,
                getcms: true
            }
        case GET_CMS_PAGES:
            return {
                ...state,
                cms: action.payload.cms,
                pagination: action.payload.pagination,
                getCmsPagesAuth: true
            }
        case BEFORE_CMS_PAGE:
            return {
                ...state,
                cms:null,
                pagination:null,
                getcms: false,
                getCmsPagesAuth: false
            }
        default:
            return { ...state }
    }
}
export default CmsReducer;