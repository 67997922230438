import React from "react";
import {Row} from "react-bootstrap";
import {Images} from "../../assets/asset";
import Slider from "react-slick";

var BattleFieldsettings = {
    className: "center",
    centerMode: true,
    dots: false,
    arrows: false,
    infinite: false,
    loop:true,
    initialSlide: 0,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
            },
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};
function BattleFieldSlider(props) {
    return (
        <div className="battle-slider">
            <div className="battlefield-slider">
                <Slider {...BattleFieldsettings}>
                    {
                        props.images.map((image, index) => {
                            return(
                                <div className="image-container" key={index}>
                                    <img src={image} className="img-fluid" alt="img" />
                                </div>
                            )
                        })
                    }
                </Slider>
            </div>
        </div>
    );
}

export default BattleFieldSlider;
