import { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { beforeGames, getGames } from '../GamesDetail/Game.action'
import { Images } from "../../assets/asset";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import Pagination from "rc-pagination";
import localeInfo from 'rc-pagination/lib/locale/en_US';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { ENV } from '../../config/config'
import "./GamesList.css";
import "rc-pagination/assets/index.css";


function GamesList(props) {

    const [games, setGames] = useState([])
    const [Page, setPage] = useState(1)
    const [loader, setLoader] = useState(true)
    const [pagination, setPagination] = useState({})

    useEffect(() => {
        let qs = ENV.objectToQueryString({launched : true})
        props.getGames( qs, { page: 1, limit: 10 })
    }, [])

    useEffect(() => {
        if (props.game.getGames) {
            setGames(props.game.games.games)
            setPagination(props.game.games.pagination)
            setLoader(false)
            props.beforeGames()
        }
    }, [props.game.getGames])

    const onPageChange = async (page) => {
        setLoader(true)
        setPage(page)
        let qs = ENV.objectToQueryString({launched : true})
        props.getGames(qs, { page: page, limit: 10 })
    }


    return (
        <div className="feature-pg all-games-listing">
            {
                loader ?
                    <FullPageLoader />
                    :
                    <Container fluid className="custom-container">
                        <div className="d-flex mb-3 mb-md-5">
                            <Link to="/explore-games" className="back-btn">
                                <FontAwesomeIcon icon={faArrowLeft} className="me-3" />
                                Back
                            </Link>
                        </div>
                        <div className="mb-4 mb-md-5">
                            <h1 className="section-heading">Games List</h1>
                        </div>
                        <Row>
                            {
                                games && games.length ?
                                    games.map((game, index) => {
                                        return (
                                            <Col xl={3} lg={4} md={6}>
                                                <div className="d-flex featured-card">
                                                    <div className="d-flex card-left-content">
                                                        <div className="image-outer-container">
                                                            <img src={game?.icon ? game?.icon : Images.soldier1} className="img-fluid" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-column card-right-content ">
                                                        <div className="card-heading">
                                                            <h4>{game?.name}</h4>
                                                        </div>
                                                        <div className="ft-common--text">
                                                            <p>{game?.tagLine}</p>
                                                        </div>
                                                        <div className="icons mb-2">
                                                            

                                                            { // 1=android , 2=ios , 3=cross-platform 
                                                                game?.platformType === 1 &&
                                                                <>
                                                                    <div>
                                                                        <img src={Images.androidstore} alt="Icon" className="img-fluid" />
                                                                    </div>
                                                                </>
                                                            }

                                                            {
                                                                game?.platformType === 2 &&
                                                                <>
                                                                    <div>
                                                                        <img src={Images.applestore} alt="Icon" className="img-fluid" />
                                                                    </div>
                                                                </>
                                                            }
                                                            {
                                                                game?.platformType === 3 &&
                                                                <>
                                                                    <div>
                                                                        <img src={Images.unity} alt="Icon" className="img-fluid" />
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                        <div className="game-feature-s-btns d-flex justify-content-between">
                                                            <div className="d-flex">
                                                                <Link to={`/games-detail/${game?._id}`} className="small-common-btn hover-effect btn btn-playgame">
                                                                    <span>Play</span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    })
                                    : ''
                            }
                        </Row>
                        <>
                            {
                                games && games.length ? <Pagination
                                    className="m-3"
                                    defaultCurrent={1}
                                    pageSize // items per page
                                    current={Page > pagination?.pages ? pagination.pages : Page} // current active page
                                    total={pagination?.pages ? pagination.pages : Page} // total pages
                                    onChange={onPageChange}
                                    locale={localeInfo}
                                />
                                    : ''
                            }
                        </>
                    </Container>
            }
        </div>
    );
}

const mapStateToProps = state => ({
    game: state.game,
    error: state.error,
});

export default connect(mapStateToProps, { beforeGames, getGames })(GamesList);