import { toast } from 'react-toastify';
import axios from 'axios'
import {BEFORE_FRONT_NEW,GET_FRONT_NEW, GET_NEWS, GET_ERRORS} from '../../redux/types';
import { emptyError } from '../../redux/shared/error/error.action';
import { ENV } from '../../config/config';

export const beforeNews = () => {
    return {
        type: BEFORE_FRONT_NEW
    }
}


export const getAllNews = (qs = '',body) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}news/list`;

    if (qs)
    url += `?${qs}`

    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data.success) {
            // toast.success(data.message)
            dispatch({
                type: GET_FRONT_NEW,
                payload: data.data
            })
        } 
        else {
            // toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data.data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};


export const getNewsById = (Id) => dispatch => {
    // /get/:newsId
    dispatch(emptyError());
    let url = `${ENV.url}news/get/${Id}`;

    // if (qs)
    //     url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            // toast.success(data.message)
            dispatch({
                type: GET_NEWS,
                payload: data.news
            })
        } 
        else {
            // toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data.news
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
}
