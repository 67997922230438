import React from "react";
import PlayerBanner from "./PlayerBanner";
import PlayerHowWorks from "./PlayerHowWorks";
import PlayersLoyalityprogram from "./PlayersLoyalityprogram";
import "./Players.css";
import { Helmet } from "react-helmet";

function Players() {
    return (
        <>
            <Helmet>
                <title>Players | Deskillz | Esports, Mobile Gaming Tournament</title>
            </Helmet>
            <div className="developer-pg-wraper">
                <PlayerBanner />
                <PlayerHowWorks />
                <PlayersLoyalityprogram />
            </div>
        </>
    );
}

export default Players;
