
import {BEFORE_COMING,GET_COMING} from '../../../redux/types'
const initialState = { whatscoming:null, getwhatscoming:false }

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_COMING:
            return {...state, whatscoming: action.payload, getwhatscoming: true }
       
        case BEFORE_COMING:
            return {...state, getwhatscoming:false }
            
        default:
            return {...state }
    }
}