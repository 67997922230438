import React, { useEffect, useState } from "react";
import { Container, Form, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { getAllContact } from './Contact.action'
import { connect } from 'react-redux'
import "./Contact.css";
import { Helmet } from "react-helmet";

function Contact(props) {

    const [discord, setDiscord] = useState('')
    const [support, setSupport] = useState('')
    const [partner, setPartner] = useState('')

    useEffect(() => {
        props.getAllContact()
    }, [])



    useEffect(() => {
        if (props.contacts.getcontacts) {
            const { discord, support, partner } = props?.contacts?.contacts
            setDiscord(discord ? discord : '')
            setSupport(support ? support : '')
            setPartner(partner ? partner : '')
        }
    }, [props.contacts.getcontacts])


    // console.log(props.contacts)
    return (
        <>
            <Helmet>
                <title>Contacts | Deskillz | Esports, Mobile Gaming Tournament</title>
            </Helmet>
            <div className="news-bg">
                <Container>
                    <div className="develper-banner-content">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <h1 className="subpg-comon-heading">Contact Us</h1>
                            <div className="text-wraper">
                                <p className="subpg-comon-text text-center">Feel free to drop us a line below!</p>
                            </div>
                        </div>
                    </div>
                    <div className="contact-wrapper">
                        {/* <Row>
                            <Col lg="8">
                                <div className="form-wrapper">
                                    <Form>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Control type="text" placeholder="Your Name" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Control type="email" placeholder="Email address" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Control type="text" placeholder="Your Phone" />
                                        </Form.Group>
                                        <Form.Group className="" controlId="exampleForm.ControlTextarea1">
                                            <Form.Control as="textarea" rows={7} placeholder="Your Message" />
                                        </Form.Group>
                                        <div className="action-btn">
                                            <button className="btn red-btn btn-playgame small">
                                                <span>Send</span>
                                            </button>
                                        </div>
                                    </Form>
                                </div>
                            </Col>
                            <Col lg="4">
                                <div className="contact-info">
                                    <h4>Contact Info</h4>
                                    <ul>
                                        <li>
                                            <Link to="#">
                                                <span className="fa fa-map-marker"></span> Office # 335/R Block, Main Blvd, Johar Town, Lahore Pakistan
                                            </Link>
                                        </li>
                                        <li>
                                            <a href="mailto:info@deskillz.com">
                                                <span className="fa fa-envelope"></span>info@deskillz.com
                                            </a>
                                        </li>
                                        <li>
                                            <a href="tel:042-1234567890">
                                                <span className="fa fa-phone"></span> 042-1234567890
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="social-icons">
                                        <a className="instagram-bg" href="/" target="_blank">
                                            <FontAwesomeIcon icon={faInstagram} />
                                        </a>
                                        <a className="faTwitter-bg" href="/" target="_blank">
                                            <FontAwesomeIcon icon={faTwitter} />
                                        </a>
                                        <a className="discord-bg" href="/" target="_blank">
                                            <FontAwesomeIcon icon={faDiscord} />
                                        </a>

                                        <a className="telegram-bg" href="/" target="_blank">
                                            <FontAwesomeIcon icon={faTelegram} />
                                        </a>
                                    </div>
                                </div>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col sm="12">
                                <div className="contact-info">
                                    <h4>Contact Info</h4>
                                    <div className="contact-other-detail">
                                        <h5> Discord Channel :</h5>
                                        <a href={discord} target="_blank" ><h6>{discord}</h6> </a>

                                        <p>Join and help support our decentralized gaming community. Our team and community members will be glad to assist you. </p>
                                    </div>
                                    <div className="contact-other-detail">
                                        <h5>Support :</h5>
                                        <a href={`mailto:${support}`} >
                                            <span className="fa fa-envelope"></span>{support}
                                        </a>
                                        <h6></h6>
                                        <p>We are happy to answer any questions you might have. </p>
                                    </div>
                                    <div className="contact-other-detail">
                                        <h5>Partner :</h5>
                                        <a href={`mailto:${partner}`}>
                                            <span className="fa fa-envelope"></span>{partner}
                                        </a>
                                        <p>We are always looking for innovative opportunities to help each other grow. Let us know what is on your mind. </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
}


const mapStateToProps = state => ({
    contacts: state.contacts,
    error: state.error,
});

export default connect(mapStateToProps, { getAllContact })(Contact);
