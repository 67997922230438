import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Images } from "../../assets/asset";
import { getAllNews, getNewsById, beforeNews } from './LatestNews.action'

import { connect } from 'react-redux'
import defaultimg from "../../assets/images/defaultImg.png"
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import { ENV } from '../../config/config'
import localeInfo from 'rc-pagination/lib/locale/en_US';
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import "./LatestNews.css";
import { Link, useParams } from "react-router-dom";

function LatestNews(props) {
    const { Id } = useParams()
    const [news, setNews] = useState([]);
    const [ oneNews, setNew] = useState({});
    const [loader, setLoader] = useState(true)
    const [Page, setPage] = useState(1)
    const [pagination, setPagination] = useState(null)

    useEffect(() => {
        window.scroll(0, 0)
        const qs = ENV.objectToQueryString({ page: Page, limit: 10 })
        props.getAllNews(qs)
    }, [])



    useEffect(() => {
        if (props.news.getnews) {
            const { news, pagination } = props?.news
            setLoader(false)
            setNews(news)
            setPagination(pagination)
            props.beforeNews()
        }

    }, [props.news.getnews])

    useEffect(() => {
        if (props.news.getNewAuth) {
            const { oneNews  } = props?.news
            setNew(oneNews)
            setLoader(false)
            props.beforeNews()
        }

    }, [props.news.getNewAuth])


    useEffect(() => {
        if (Id) {
            // getNewAuth
            props.getNewsById(Id)
            setLoader(true)
            // console.log("news: ",news)
            // let filterNews = news.map(item => {
            //     if (item._id === Id) {
            //         return item
            //     }
            // })
            // console.log("filterNews: ",filterNews)
            // setNews(filterNews)
        }
        else {
            const qs = ENV.objectToQueryString({ page: Page, limit: 10 })
            props.getAllNews(qs)
        }
    }, [Id])

    const onPageChange = async (page) => {

        setPage(page)
        const qs = ENV.objectToQueryString({ page: page, limit: 10 })
        props.getAllNews(qs)
    }

    return (
        <>
            {
                loader ?
                    <FullPageLoader />
                    :
                    <>
                        {

                            <>
                                <div className="news-bg">
                                    <Container fluid className="custom-container">
                                        <div className="develper-banner-content">
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                <h1 className="subpg-comon-heading">LATEST NEWS</h1>
                                                <div className="text-wraper">
                                                    <p className="subpg-comon-text text-center">LATEST NEWS</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="our-values">
                                            <div className="d-flex justify-content-center align-items-center flex-column space-common">
                                                <h3 className="common-heading">Latest News about DeSkills</h3>
                                                <p className="paragraph-text-2">
                                                    <span className="red-text">Phasellus finibus enim nulla,</span> congue libero efficitur at.
                                                </p>
                                            </div>
                                            {
                                                !Id ?
                                                    <>
                                                        <Row className="news-description">
                                                            {news && news.map((n, index) => {
                                                                return (
                                                                    <Col lg="4" md="6" className="mb-3" key={index}>
                                                                        {/* <Link to={`/news/${n._id}`}> */}

                                                                            <div className="card-contain">
                                                                                <Link className="w-100" to={`/news/${n._id}`}>
                                                                                    <div className="news-cardimage-box">
                                                                                        <img src={n.thumbnail} onError={(e) => { e.target.onerror = null; e.target.src = defaultimg }} alt="img" />
                                                                                    </div>
                                                                                </Link>
                                                                                <div className="card-inner-content position-relative w-100">
                                                                                    <h4 class="h-100"><Link to={`/news/${n._id}`}>{n.title}</Link></h4>
                                                                                    <div className="text-restrict news-cardimage-box">
                                                                                        <p dangerouslySetInnerHTML={{ __html: n.description }}></p>
                                                                                    </div>
                                                                                    <div className="btn-more-holder">
                                                                                        <Link className="btn-read-more hover-effect btn btn-playgame" to={`/news/${n._id}`}>
                                                                                            <span>Read More</span>
                                                                                        </Link>
                                                                                    </div>
                                                                                    {/* <div className="content" ></div> */}
                                                                                </div>
                                                                            </div>
                                                                        {/* </Link> */}
                                                                    </Col>
                                                                )
                                                            })}


                                                            {news && !news.length != 0 && <Pagination
                                                                className="m-3"
                                                                defaultCurrent={1}
                                                                pageSize // items per page
                                                                current={Page > pagination?.pages ? pagination.pages : Page} // current active page
                                                                total={pagination?.pages ? pagination.pages : Page} // total pages
                                                                onChange={onPageChange}
                                                                locale={localeInfo}
                                                            />}




                                                            {/* <Col lg="4" md="6" className="mb-3">
                                                                <div className="card-contain">
                                                                    <div className="news-cardimage-box">
                                                                        <img src={Images.newsimg6} alt="img" />
                                                                    </div>
                                                                    <div className="card-inner-content">
                                                                        <h4>Sed viverra mattis dictum. In ultricies tellus in auctor semper. Sed blandit arcu sem,</h4>
                                                                        <p className="text-restrict">Phasellus finibus enim nulla, quis ornare odio facilisis eu. Suspendisse ornare ante sit amet arcu semper, vel eleifend tortor egestas. Aenean luctus, lorem in hendrerit interdum, leo orci egestas diam, ac euismod massa est et turpis. Etiam auctor lectus vel neque convallis pharetra. Ut turpis eros, aliquet non ante id, interdum placerat erat. Curabitur sit amet eros vel orci venenatis hendrerit. Cras sagittis sagittis sagittis. In hac habitasse platea dictumst. Phasellus diam erat, porttitor sed ligula at, ultricies auctor tellus. Donec ut sem in turpis ultrices suscipit ut auctor tellus. Quisque a tincidunt ipsum […]</p>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg="4" md="6" className="mb-3">
                                                                <div className="card-contain">
                                                                    <div className="news-cardimage-box">
                                                                        <img src={Images.newsimg4} alt="img" />
                                                                    </div>
                                                                    <div className="card-inner-content">
                                                                        <h4>Big Buck Hunter: Marksman mobile game version exclusively on Deskillz’</h4>
                                                                        <p className="text-restrict">Play Mechanix, Creator of the Big Buck Hunter: Marksman Mobile Game, Recognized As The Year’s “Best In Class” Developer Honored for Creativity, Innovation, and Growth on Skillz’ Platform Recognizing the talents of its developer community that builds highly competitive game experiences for everyone […]</p>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg="4" md="6" className="mt-lg-4 mb-3">
                                                                <div className="card-contain">
                                                                    <div className="news-cardimage-box">
                                                                        <img src={Images.newsimg2} alt="img" />
                                                                    </div>
                                                                    <div className="card-inner-content">
                                                                        <h4>Etiam convallis elementum sapien, a aliquam turpis aliquam vitae. Praesent sollicitudin felis vel mi</h4>
                                                                        <p className="text-restrict">Aenean sed lorem est. Sed quis neque ut nibh suscipit imperdiet ac non augue. Aenean ornare sit amet lectus non tristique. Nunc ut volutpat lectus. Nulla velit augue, pulvinar sed nisi sit amet, eleifend fermentum est. Quisque nibh justo, congue ut erat at, aliquet efficitur purus. Integer venenatis odio vitae orci efficitur mollis. Donec ultrices diam dictum dignissim vestibulum. Proin eleifend nunc nunc. Sed non arcu eget lorem viverra sodales […]</p>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg="4" md="6" className="mt-lg-4 mb-3">
                                                                <div className="card-contain">
                                                                    <div className="news-cardimage-box">
                                                                        <img src={Images.newsimg3} alt="img" />
                                                                    </div>
                                                                    <div className="card-inner-content">
                                                                        <h4>Integer at faucibus urna. Nullam condimentum leo id elit sagittis auctor</h4>
                                                                        <p className="text-restrict">Proin ac quam et lectus vestibulum blandit. Nunc maximus nibh at placerat tincidunt. Nam sem lacus, ornare non ante sed, ultricies fringilla massa. Ut congue, elit non tempus elementum, sem risus tincidunt diam, vitae sodales diam ipsum vitae purus. Fusce tristique erat nulla, vel viverra mi auctor non. Integer ante lorem, dictum at felis at, bibendum egestas augue. Aenean vestibulum lacus dolor, id tincidunt arcu pellentesque ut. Mauris sed venenatis diam, quis aliquam odio. Mauris lobortis augue placerat elit ultrices, non laoreet nunc semper. Phasellus dolor sapien […]</p>
                                                                    </div>
                                                                </div>
                                                            </Col> */}

                                                        </Row>
                                                    </>
                                                    :
                                                    <>
                                                        {oneNews ?
                                                            <Col md="12" className="mb-3" >

                                                                <div className="card-contain">
                                                                    <div className="news-cardimage-box">
                                                                        <img src={oneNews.thumbnail} onError={(e) => { e.target.onerror = null; e.target.src = defaultimg }} alt="img" />
                                                                    </div>
                                                                    <div className="card-inner-content w-100">
                                                                        <h4>{oneNews.title}</h4>
                                                                        <div dangerouslySetInnerHTML={{ __html: oneNews.description }}></div>
                                                                        {/* <div className="content" ></div> */}
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            : ""
                                                        }
                                                    </>
                                            }
                                        </div>
                                    </Container>
                                </div>
                            </>
                        }
                    </>
            }

        </>
    );
}


const mapStateToProps = state => ({
    news: state.news,
    error: state.error,
});

export default connect(mapStateToProps, { getAllNews, getNewsById, beforeNews })(LatestNews);
