import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Images } from "../../assets/asset";
import "./AboutUs.css";

function AboutBanner() {
    return (
        <>
            <div className="about-banner-pg">
                <Container fluid className="custom-container">
                    <div className="develper-banner-content">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <h1 className="subpg-comon-heading">Share Our Passion for Gaming!</h1>
                            <div className="text-wraper">
                                <p className="subpg-comon-text text-center mb-3">Deskillz's mission is to distupt the blockchain gaming ecosystem by
                                    providing a low-entry barrier for all players, developers and investors to have fun and earn revenue mutually.
                                </p>
                                <p className="subpg-comon-text text-center mb-3">With 35 years of gaming experience and a veteran crypto investor, Deskillz
                                    is changing the stigma that surrounds most blockchain projects, by offering a platform with real-world utilities and
                                    revenue generation.
                                </p>
                                <p className="subpg-comon-text text-center mb-3">We’re different than the rest — no up-front NFT game asset purchases
                                    required. Just download the game and we take care of the rest!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="our-values">
                        <div className="d-flex justify-content-center align-items-center flex-column">
                            <h3 className="common-heading">Our Values</h3>
                            {/* <p className="paragraph-text-2">
                                <span className="red-text">Maecenas tristique sem neque,</span> congue libero efficitur at.
                            </p> */}
                        </div>
                        <div className="values-detail-info system-integration-detail">
                            <Row>
                                {/* /lg={{span: 3, offset: 1}} */}
                                <Col lg="3" sm="6" className="d-flex  flex-column justify-content-center align-items-center">
                                    <div className="border-items-around">
                                        <div className="test-inner user-box">
                                            <img src={Images.GameFi} alt="img" />
                                        </div>
                                    </div>
                                    <div className="description-services">
                                        <h6>GameFi</h6>
                                    </div>
                                </Col>
                                <Col lg="3" sm="6" className="d-flex  flex-column justify-content-center align-items-center">
                                    <div className="border-items-around">
                                        <div className="test-inner">
                                            <img src={Images.Security} alt="img" />
                                        </div>
                                    </div>
                                    <div className="description-services">
                                        <h6>Security</h6>
                                    </div>
                                </Col>
                                <Col lg="3" sm="6" className="d-flex  flex-column justify-content-center align-items-center">
                                    <div className="border-items-around">
                                        <div className="test-inner">
                                            <img src={Images.AboutPrivacy} alt="img" />
                                        </div>
                                    </div>
                                    <div className="description-services">
                                        <h6>Privacy</h6>
                                    </div>
                                </Col>
                                <Col lg="3" sm="6" className="d-flex  flex-column justify-content-center align-items-center">
                                    <div className="border-items-around">
                                        <div className="test-inner">
                                            <img src={Images.Decentralized} alt="img" />
                                        </div>
                                    </div>
                                    <div className="description-services">
                                        <h6>Decentralization</h6>
                                    </div>
                                </Col>
                                {/* <Col lg="2" md="6" className="d-flex  flex-column justify-content-center align-items-center">
                                    <div className="border-items-around">
                                        <div className="test-inner">
                                            <img src={Images.aboutfingerprint} alt="img" />
                                        </div>
                                    </div>
                                    <div className="description-services">
                                        <h6>Privacy</h6>
                                    </div>
                                </Col> */}

                            </Row>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
}
export default AboutBanner;
