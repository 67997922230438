import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Images } from "../../assets/asset";
import "./Players.css";

function PlayerHowWorks() {
    return (
        <div className="how-it-works top-bottom-same-padding">
            <Container fluid className="custom-container">
                <Row className="jusify-content-center align-items-center space-common">
                    <Col lg="7" className="order-lg-1 order-2 mt-5">
                        <div className="program-inner-content">
                            <h2 className="mb-3 text-capitalize">competition</h2>
                            {/* <p>Sed a magna semper, porta purus eu, ullamcorper ligula. Nam sit amet consectetur sapien. Etiam dui ipsum, viverra vel turpis ut, dignissim elementum mauris. Sed dapibus auctor scelerisque.</p> */}
                            <ul className="list-info">
                                <li>
                                    <div className="d-flex align-items-center">
                                        <img src={Images.tick} className="img-fluid" alt="img" />
                                        Get matched up through our Smart Match System, placing you against players with similar skill levels.
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <img src={Images.tick} className="img-fluid" alt="img" />
                                        Engage in different competitions.
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <img src={Images.tick} className="img-fluid" alt="img" />
                                        Asynchronous match — play the same match with the same conditions at different times.
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <img src={Images.tick} className="img-fluid" alt="img" />
                                        Synchronous match — Play at the same time, interacting with each other with the same conditions.
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <img src={Images.tick} className="img-fluid" alt="img" />
                                        Tournaments — The winner of each match plays winners of the other matches. Each round awards a pre-determined prized pool split between top participants.
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col lg="5" className="order-lg-2 order-1">
                        <div className="d-flex justify-content-lg-end">
                            <img src={Images.competition} className="img-fluid" alt="img" />
                        </div>
                    </Col>
                </Row>
                <Row className="align-items-center">
                    <Col lg="6">
                        <div className="d-flex">
                            <img src={Images.Handsofdating} className="img-fluid" alt="img" />
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="program-inner-content mt-5">
                            <h2 className="mb-3 text-capitalize">How You Get Paid</h2>
                            {/* <p>Sed a magna semper, porta purus eu, ullamcorper ligula. Nam sit amet consectetur sapien. Etiam dui ipsum, viverra vel turpis ut, dignissim elementum mauris. Sed dapibus auctor scelerisque.</p> */}
                            <ul className="list-info">
                                <li>
                                    <div className="d-flex align-items-center">
                                        <img src={Images.tick} className="img-fluid" alt="img" />
                                        Get paid in $USDT
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <img src={Images.tick} className="img-fluid" alt="img" />
                                        Collect achievement awards, and earn loyalty points every time you play in a competition.
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <img src={Images.tick} className="img-fluid" alt="img" />
                                        Claim your earnings anytime through your account using your MetaMask wallet.
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default PlayerHowWorks;
