import {Container, Row, Col} from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

function GamesDetailSkeleton() {
   
    return (
        <div className="game-detail-pg-wraper">
            <div className="" >
                <div className="battle-upper-section">
                    <Container fluid className="custom-container">
                        <div className="d-flex">
                            <SkeletonTheme color="#202020" highlightColor="#d31d05">
                                <Skeleton count={2} enableAnimation={true}  />
                            </SkeletonTheme>
                        </div>
                        <div className="d-flex">
                            <div className="battelfield-img-container">
                                <SkeletonTheme color="#202020" highlightColor="#d31d05">
                                    <Skeleton count={2} enableAnimation={true}  />
                                </SkeletonTheme>
                            </div>
                        </div>
                        <div className="battlefield-detail">
                            <Row className="align-items-end">
                                <Col lg="6">
                                    <div className="d-flex flex-column">
                                        <SkeletonTheme color="#202020" highlightColor="#d31d05">
                                            <Skeleton count={2}  />
                                        </SkeletonTheme>
                                    </div>
                                    <div className=" d-flex flex-md-row flex-column">
                                        <SkeletonTheme color="#202020" highlightColor="#d31d05">
                                            <Skeleton count={2}  />
                                        </SkeletonTheme>
                                    </div>
                                </Col>
                                <Col lg="6" className="d-flex flex-lg-row flex-column justify-content-end align-items-sm-end align-items-start mt-lg-5 mt-4">
                                    <div className="d-flex flex-sm-row flex-column">
                                        <div className="d-flex availablefor">
                                            <SkeletonTheme color="#202020" highlightColor="#d31d05">
                                                <Skeleton count={2} enableAnimation={true}  />
                                            </SkeletonTheme>
                                        </div>
                                        <div className="d-flex available-gadgets">
                                            <SkeletonTheme color="#202020" highlightColor="#d31d05">
                                                <Skeleton count={2} enableAnimation={true}  />
                                            </SkeletonTheme>
                                        </div>
                                        <div className="d-flex available-gadgets ms-lg-5 ms-sm-3 ms-0 mt-sm-0 mt-4">
                                            <SkeletonTheme color="#202020" highlightColor="#d31d05">
                                                <Skeleton count={2} enableAnimation={true}  />
                                            </SkeletonTheme>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <div className="battle-slider">
                    <div className="battlefield-slider">
                        <div className="image-container" >
                            <SkeletonTheme color="#202020" highlightColor="#d31d05">
                                <Skeleton count={2} enableAnimation={true}  />
                            </SkeletonTheme>
                        </div>
                        <div className="image-container" >
                            <SkeletonTheme color="#202020" highlightColor="#d31d05">
                                <Skeleton count={2} enableAnimation={true}  />
                            </SkeletonTheme>
                        </div>
                        <div className="image-container" >
                            <SkeletonTheme color="#202020" highlightColor="#d31d05">
                                <Skeleton count={2} enableAnimation={true}  />
                            </SkeletonTheme>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GamesDetailSkeleton;
