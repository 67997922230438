// ERRORS
export const EMPTY_ERRORS = 'EMPTY_ERRORS';
export const GET_ERRORS = 'GET_ERRORS';

// ADMIN
export const BEFORE_USER = 'BEFORE_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const SIGNUP_USER = 'SIGNUP_USER';  
export const UPDATE_PROFILE_USER = 'UPDATE_PROFILE_USER';   




export const ADD_ADMIN = 'ADD_ADMIN';
export const LOGIN_ADMIN = 'LOGIN_ADMIN';
export const GET_ADMIN = 'GET_ADMIN';
export const GET_ADMINS = 'GET_ADMINS';
export const UPDATE_ADMIN = 'UPDATE_ADMIN';
export const DELETE_ADMIN = 'DELETE_ADMIN';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const GET_USER_VERIFY = 'GET_USER_VERIFY';
export const BEFORE_USER_VERIFY = 'BEFORE_USER_VERIFY';


// Settings
export const GET_SETTINGS = 'GET_SETTINGS';
export const EDIT_SETTINGS = 'EDIT_SETTINGS';
export const BEFORE_SETTINGS = 'BEFORE_SETTINGS';



// CONTACTS
export const BEFORE_CONTACT = 'BEFORE_CONTACT';
export const GET_CONTACTS = 'GET_CONTACTS';
export const EDIT_CONTACT = 'EDIT_CONTACT';

// ACTIVITY
export const BEFORE_ACTIVITY = 'BEFORE_ACTIVITY'
export const GET_ACTIVITIES = 'GET_ACTIVITIES'




//THEME
export const BEFORE_THEME = 'BEFORE_THEME'
export const ADD_THEME    = 'ADD_THEME'
export const GET_THEME    = 'GET_THEME'
export const EDIT_THEME   = 'EDIT_THEME'
export const DELETE_THEME = 'DELETE_THEME'
//Tournament

//GAMES
export const GET_GAMES =   'GET_GAMES'
export const GET_GAME =   'GET_GAME'
export const GET_FEATURED_GAMES =   'GET_FEATURED_GAMES'
export const GET_POPULAR_GAMES =   'GET_POPULAR_GAMES'
export const GET_NEW_GAMES =   'GET_NEW_GAMES'
export const BEFORE_GAME = 'BEFORE_GAME'
export const DELETE_GAME = 'DELETE_GAME'
export const UPSERT_GAME = 'UPSERT_GAME'
export const DASHBOARD_GET_GAMES =   'DASHBOARD_GET_GAMES' 
export const UPSERT_DASHBOARD_GAME =   'UPSERT_DASHBOARD_GAME' 
export const GET_GENRES =   'GET_GENRES' 
export const GET_GENRE_GAMES =   'GET_GENRE_GAMES' 
export const GET_GENRE = 'GET_GENRE'


//DASHBOARD
export const UPSERT_DASHBOARD_LEARNING =   'UPSERT_DASHBOARD_LEARNING' 
export const GET_LEARNINGS =   'GET_LEARNINGS' 
export const BEFORE_DASHBOARD =   'BEFORE_DASHBOARD' 
export const GET_DASHBOARD = 'GET_DASHBOARD'

//AboutUs
export const BEFORE_ABOUT_US = 'BEFORE_ABOUT_US'
export const GET_NEWS_ABOUT_US = 'GET_NEWS_ABOUT_US'

//GAMES
export const GET_TOURNAMENT    = 'GET_TOURNAMENT'
export const BEFORE_TOURNAMENT = 'BEFORE_TOURNAMENT'
export const DELETE_TOURNAMENT = 'DELETE_TOURNAMENT'
export const UPSERT_TOURNAMENT = 'UPSERT_TOURNAMENT'


//SUPPORT
export const GET_SUPPORT_GAMES = 'GET_SUPPORT_GAMES'
export const GET_SUPPORT_SDK = 'GET_SUPPORT_SDK'
export const BEFORE_SUPPORT = 'BEFORE_SUPPORT'

//PLAYERS SUPPORT
export const GET_PLAYERS_SUPPORT_GAMES = 'GET_PLAYERS_SUPPORT_GAMES'
export const BEFORE_PLAYERS_SUPPORT = 'BEFORE_PLAYERS_SUPPORT'


//FAQS
export const GET_FAQS = 'GET_FAQS'
export const BEFORE_FAQS = 'BEFORE_FAQS'


export const BEFORE_CONTACT_US = 'BEFORE_CONTACT_US'
export const GET_CONTACT_US = 'GET_CONTACT_US'

export const BEFORE_FRONT_NEW = 'BEFORE_FRONT_NEW'
export const GET_FRONT_NEW = 'GET_FRONT_NEW'
//CMS
export const GET_CMS = 'GET_CMS'
export const GET_CMS_PAGE = 'GET_CMS_PAGE'
export const GET_CMS_PAGES ='GET_CMS_PAGES'
export const BEFORE_CMS_PAGE = 'BEFORE_CMS_PAGE'
//Footer
export const GET_FOOTER = 'GET_FOOTER'
export const BEFORE_FOOTER = 'BEFORE_FOOTER'

//Coming
export const BEFORE_COMING = 'BEFORE_COMING'
export const GET_COMING = 'GET_COMING'
//new
export const GET_NEWS = 'GET_NEWS'





















