import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import {Images} from "../../assets/asset";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import "./Players.css";
import { Link } from "react-router-dom";
import { ENV } from "./../../config/config";

function PlayerBanner() {
    return (
        <div className="players-pg">
            <div className="players-banner">
                <Container fluid className="custom-container">
                    <div className="develper-banner-content">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <h1 className="subpg-comon-heading">BECOME A DESKILLZ HERO</h1>
                            <div className="text-wraper">
                                <p className="subpg-comon-text text-center">Compete, earn rewards and bragging rights through our decentralized blockchain gaming community. It’s quick and easy!</p>
                            </div>
                            <div className="btn-holder d-flex justify-content-center flex-column">
                                <a className="btn red-btn btn-playgame" href={`${ENV.deskillzPlayerSiteUrl}/login`} target="_blank">
                                    <span>Join Now</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="players-portal-part">
                        <Row>
                            <Col lg="5">
                                <div className="video-holder">
                                    <video width="100%" height="100%" poster={Images.playersvideoposter} controls>
                                        <source src="movie.mp4" type="video/mp4" />
                                        <source src="movie.ogg" type="video/ogg" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </Col>
                            <Col lg="7" className="d-flex justify-content-lg-end mt-lg-0 mt-5">
                                <div className="program-inner-content">
                                    <h2 className="mb-3">Getting Started</h2>
                                    {/* <p>Sed a magna semper, porta purus eu, ullamcorper ligula. Nam sit amet consectetur sapien. Etiam dui ipsum, viverra vel turpis ut, dignissim elementum mauris. Sed dapibus auctor scelerisque.</p> */}
                                    <ul className="list-info">
                                        <li>
                                            <div className="d-flex align-items-center">
                                                <FontAwesomeIcon icon={faCheck} className="me-3" />
                                                It’s hassle free! Just register an account with an email or MetaMask wallet. No personal information is required.
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex align-items-center">
                                                <FontAwesomeIcon icon={faCheck} className="me-3" />
                                                Enjoy our loyalty program and achievement reward features — rank up to MVP Tier 6 status and get 20% more bonus payouts.
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex align-items-center">
                                                <FontAwesomeIcon icon={faCheck} className="me-3" />
                                                Simply download the desired game apps from our platform, initiate or join a match, and play!
                                            </div>
                                        </li>
                                    </ul>
                                    {/* <div className="btn-holder">
                                        <button className="hover-effect btn btn-playgame">
                                            <span>Find Your Game</span>
                                        </button>
                                    </div> */}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default PlayerBanner;
