import React, { useEffect, useState } from "react";
import DevelopersBanner from "./DevelopersBanner";
import DevelopersHowWorks from "./DevelopersHowWorks";
import GamesFeature from "./GamesFeature";
import "./Developers.css";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import { Helmet } from "react-helmet";

function Developers() {
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        setLoader(false)
    }, [])
    return (
        <>
            <Helmet>
                <title>Blockchain Gaming Ecosystem Integration & Development | Deskillz</title>
            </Helmet>
            {
                loader ?
                    <FullPageLoader />
                    :
                    <div className="developer-pg-wraper">
                        <DevelopersBanner />
                        <DevelopersHowWorks />
                        <GamesFeature />
                    </div>
            }
        </>
    );
}

export default Developers;
