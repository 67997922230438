import {Container, Row, Col} from "react-bootstrap";
import {Images} from "../../../assets/asset";
import { Link } from "react-router-dom";
import "./Banner.css";
import { ENV } from "./../../../config/config";
function Banner() {
    return (
        <>
            <section id="site-banner" className="banner-content">
                <Container fluid className=" custom-container">
                    <Row>
                        <Col lg="7">
                            <div className="spider-image-container">
                                <img src={Images.spidergirl} className="img-fluid spider-girl walleimage-animation" alt="img" />
                                <div className="baner-spider2">
                                    <img src={Images.spiderman} className="img-fluid spidar-man walleimage-animation" alt="img" />
                                </div>
                            </div>
                        </Col>
                        <Col lg="5" className="d-flex justify-content-end">
                            <div className="baner-content">
                                <p> The Ultimate Decentralized Tournament</p>
                                <h1 className="main-heading">Gaming Platform</h1>
                                {/* <p>Available for game publishing, your game must be found</p> */}
                                <div className="btn-holder d-flex justify-content-lg-end justify-content-center flex-md-row flex-column">
                                    <Link to="/explore-games" className="hover-effect btn btn-playgame">
                                        <span>Play Games</span>
                                    </Link>
                                    <a className="btn red-btn btn-playgame ms-md-3 mt-md-0 mt-3 ms-0" href={`${ENV.deskillzDeveloperSiteUrl}/login`} target="_blank">
                                        <span>Create Game</span>
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default Banner;