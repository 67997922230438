import React, { useState, useEffect } from "react";
import { Container, Tabs, Tab, Accordion } from "react-bootstrap";
import { useParams } from "react-router-dom";
import "./Faqs.css";
import { getAllFaqs, beforeFaqs } from './Faqs.action'
import { connect } from 'react-redux'
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
function Faqs(props) {
    const { type } = useParams()
    const [currentType, setCurrentType] = useState(type === 'developer' ? 1 : 2)
    const [faqs, setFaqs] = useState([])

    const [loader, setLoader] = useState(true)

    useEffect(() => {
        let body = {}
        body.type = type === 'developers' ? 1 : 2

        console.log("body is on-mount: ", body)
        props.beforeFaqs()
        props.getAllFaqs(body)
    }, [])

    useEffect(() => {
        let body = {}
        body.type = type === 'developers' ? 1 : 2
        setCurrentType(body.type)
        setLoader(true)
        console.log("body: ", body)
        props.beforeFaqs()
        props.getAllFaqs(body)
    }, [type])

    useEffect(() => {
        if (props.faqs.getfaqs) {
            const { faqs } = props.faqs
            setLoader(false)
            setFaqs(faqs)
        }
    }, [props.faqs.getfaqs])

    return (
        <>
            {
                loader ?
                    <FullPageLoader />
                    :
                    <div className="news-bg">
                        <Container>
                            <div className="develper-banner-content">
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <h1 className="subpg-comon-heading">Have Any Questions?</h1>
                                    <div className="text-wraper">
                                        <p className="subpg-comon-text text-center">FAQs</p>
                                    </div>
                                </div>
                            </div>
                            <div className="faqs">
                                
                                {
                                    // faqs.length > 0 && <Tabs defaultActiveKey={`${faqs[0].name}`} transition={false} id="noanim-tab-example" className="">
                                    faqs.length > 0 && 
                                        <Tabs defaultActiveKey={`All`} transition={false} id="noanim-tab-example" className="">
                                            <Tab eventKey="All" title="All" key="All">
                                                <Accordion defaultActiveKey={0}>
                                                    
                                                    {
                                                        faqs.map((faq, i) => {

                                                            return faq.faqs.filter(item => {
                                                                
                                                                if (item.type === currentType) {
                                                                    console.log("item-title: ",item )
                                                                    return item
                                                                }
                                                            }).map((f, index) => {
                                                                return (
                                                                    <Accordion.Item eventKey={i+index} key={i+index}>

                                                                        <Accordion.Header>{f.title}</Accordion.Header>
                                                                        <Accordion.Body dangerouslySetInnerHTML={{ __html: f.desc }}></Accordion.Body>
                                                                    </Accordion.Item>
                                                                )
                                                            })
                                                        })
                                                    }
                                                </Accordion>
                                            </Tab>
                                            {faqs.map((faq, i) => {

                                                return (
                                                    
                                                    <Tab eventKey={faq.name} title={faq.name} key={i}>
                                                        { console.log('faq:', faq) }
                                                        <Accordion defaultActiveKey={0}>
                                                            {faq.faqs.filter(item => {
                                                                if (item.type === currentType) {
                                                                    console.log("item: ",item)
                                                                    return item
                                                                }
                                                            }).map((f, index) => {
                                                                return (
                                                                    <Accordion.Item eventKey={i+index} key={index}>
                                                                        <Accordion.Header>{f.title}</Accordion.Header>
                                                                        <Accordion.Body dangerouslySetInnerHTML={{ __html: f.desc }}></Accordion.Body>
                                                                    </Accordion.Item>
                                                                )
                                                            })
                                                            }
                                                        </Accordion>
                                                    </Tab>
                                                )
                                            })}

                                        </Tabs>
                                    }
                                </div>
                            </Container>
                        </div>
                }

        </>
    );
}


const mapStateToProps = state => ({
    faqs: state.faqs,
    error: state.error,
});

export default connect(mapStateToProps, { getAllFaqs, beforeFaqs })(Faqs);