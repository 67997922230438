import { Container, Row, Col } from "react-bootstrap";
import { Images } from "../../../assets/asset";
import { Link } from "react-router-dom";
import "./WelCome.css";
import { ENV } from "./../../../config/config";
function WelCome() {
    return (
        <>
            <section className="welcome-area">
                <Container fluid className="custom-container content-wraper">
                    <Row className="justify-content-center">
                        <Col lg="7" className="d-flex align-items-center">
                            <div className="deskillz-detail">
                                <h3 className="common-heading"> {/* Welcome to Deskillz */} Play to Win with Deskillz!</h3>
                                <p className="para-text">
                                    {/* Deskillz is a platform which allows indie devs to integratwe their existing games onto the platform ecosystem.
                                     The Deskillz platform helps developers build multi-million dollar franchises by enabling social competition 
                                     in their games. Leveraging its patented technology, Deskillz hosts billions of casual esports tournaments for
                                      millions of mobile players worldwide, and distributes millions in prizes each month. */}
                                    Deskillz provides a decentralized platform for gamers, developers and investors to share your passion for
                                    gaming. Get your game on with players worldwide, and earn great prizes!
                                </p>
                                <ul className="moral-services">
                                    <li>
                                        <div className="d-flex flex-column align-items-center">
                                            <img src={Images.gamecontroller} className="img-fluid" alt="img" />
                                            GameFi
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex flex-column align-items-center">
                                            <img src={Images.protect} className="img-fluid" alt="img" />
                                            Security
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex flex-column align-items-center">
                                            <img src={Images.privacy} className="img-fluid" alt="img" />
                                            Privacy
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex flex-column align-items-center">
                                            <img src={Images.distribute} className="img-fluid" alt="img" />
                                            Decentralized
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg="5" className="d-flex  justify-content-lg-center justify-content-start">
                            <div className="payment-in-busds">
                                <h2>PAYMENTS IN $USDT </h2>
                                <h4> Compete for exciting reward features, paid out in $USDT tokens. </h4>
                                {/* <div className="d-flex play--mode-button justify-content-lg-center justify-content-start">
                                    <a className="btn hover-effect btn btn-playgame" href={`${ENV.deskillzPlayerSiteUrl}/login`} target="_blank">
                                        <span>Join Great Platform</span>
                                    </a>
                                </div> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default WelCome;
