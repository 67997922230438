import { toast } from 'react-toastify';
import { BEFORE_FOOTER, GET_FOOTER, GET_ERRORS, GET_CMS } from '../../../redux/types';
import { emptyError } from '../../../redux/shared/error/error.action';
import { ENV } from '../../../config/config';

export const beforeFooter = () => {
    return {
        type: BEFORE_FOOTER
    }
}

export const getFooter = (qs = '', body) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}settings/get`;

    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            // toast.success(data.message)
            dispatch({
                type: GET_FOOTER,
                payload: data.settings
            })
        }
        else {
            // toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data.data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};




export const getCms = (qs = '', body) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}settings/get-cms`;

    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            // toast.success(data.message)
            dispatch({
                type: GET_CMS,
                payload: data.footerCms
            })
        }
        else {
            // toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data.data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};


