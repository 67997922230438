import React, {useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {Images} from "../../assets/asset";
import {Link} from "react-router-dom";
import { ENV } from "../../config/config";
import {QRCodeSVG} from 'qrcode.react';


function GameDetalBanner(props) {
    
    const [isActive, setActive] = useState(false);
    const toggleClass = () => {
        setActive(!isActive);
      };
    return (
        <div className="battle-upper-section">
            <Container fluid className="custom-container">
                <div className="d-flex">
                    <Link to="/explore-games" className="back-btn">
                        <FontAwesomeIcon icon={faArrowLeft} className="me-3" />
                        Back
                    </Link>
                </div>
                <div className="d-flex">
                    <div className="battelfield-img-container">
                        <img src={props?.game?.icon ? props?.game?.icon :  Images.battelfield} className="img-fluid" alt="img" />
                    </div>
                </div>
                <div className="battlefield-detail">
                    <Row className="align-items-end">
                        <Col lg="6">
                            <div className="d-flex flex-column">
                                <h3>{ props.game.name }</h3>
                                <p className="battel-text">{ props.game.description } </p>
                            </div>
                            {/* <div className=" d-flex flex-md-row flex-column">
                                {console.log("game: ",props.game)}
                                {(props.game.platformType == 2 || props.game.platformType == 3 )   ?
                                    <a href={props.game.iOSStoreLink} target="_blank">
                                        <img src={Images.appleStore} className="img-fluid" alt="img" />
                                    </a>:''
                                }
                                {(props.game.platformType == 1 || props.game.platformType == 3)   ?
                                    <a href={ENV.playStoreBundleUrl+props.game.androidPackageName}  target="_blank">
                                        <img src={Images.googlePlay} className="img-fluid ms-md-3 mt-md-0 mt-3" alt="img" />
                                    </a>
                                    :''
                                }
                            </div> */}
                        </Col>
                        <Col lg="6" className="d-flex flex-lg-row flex-column justify-content-end align-items-sm-end align-items-start mt-lg-5 mt-4">
                            <div className="d-flex flex-sm-row flex-column">
                                <div className="d-flex availablefor">
                                    <p>Available for</p>
                                </div>
                                { (props.game.platformType == 2 || props.game.platformType == 3 )  ? 
                                    <div className={`d-flex available-gadgets position-relative ${isActive ? 'active' : ''}`} onClick={toggleClass} >
                                        <img src={Images.myPhone} className="img-fluid" alt="img" />
                                        <p>Apple</p>
                                        <div className="hover-qr-code">
                                            <QRCodeSVG value={ props?.gameBuild?.gameApk ? props.gameBuild.gameApk : props.game._id ? `${ENV.deskillzMainSiteUrl}/games-detail/${props.game._id}` : ''} imageSettings={{src:Images.dskillzLogo, height:20 , width:20 ,excavate:true}} />
                                        </div>
                                    </div>
                                :'' }
                                {/* { props.game.orientation == 0 || props.game.orientation == 2 ? 
                                <div className={`d-flex available-gadgets position-relative ${isActive ? 'active' : ''}`} onClick={toggleClass}>
                                    <img src={Images.myPhoneLandscape} className="img-fluid" alt="img" />
                                    <p>Landscape</p>
                                    <div className="hover-qr-code">
                                        <QRCodeSVG value={props.gameBuild.gameApk ? props.gameBuild.gameApk : `${ENV.deskillzMainSiteUrl}/games-detail/${props.game._id}`} imageSettings={{src:Images.dskillzLogo, height:20 , width:20 , excavate:true}} />
                                    </div>
                                </div> */}
                                { (props.game.platformType == 1 || props.game.platformType == 3) ? 
                                <div className={`d-flex available-gadgets position-relative ${isActive ? 'active' : ''}`} onClick={toggleClass}>
                                    <img src={Images.myPhoneLandscape} className="img-fluid" alt="img" />
                                    <p>Android</p>
                                    <div className="hover-qr-code">
                                        <QRCodeSVG value={props?.gameBuild?.gameApk ? props?.gameBuild?.gameApk : props.game._id ? `${ENV.deskillzMainSiteUrl}/games-detail/${props.game._id}` : ''} imageSettings={{src:Images.dskillzLogo, height:20 , width:20 , excavate:true}} />
                                    </div>
                                </div>
                                :'' }
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}

export default GameDetalBanner;
