import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Images } from "../../assets/asset";
import "./Developers.css";

function DevelopersHowWorks() {
    return (
        <div className="how-it-works top-bottom-same-padding">
            <Container fluid className="custom-container">
                <Row className="jusify-content-center align-items-center space-common">
                    <Col lg="7" className="order-lg-1 order-2 mt-lg=0 mt-4">
                        <div className="program-inner-content">
                            <h2>How It Works</h2>
                            {/* <p>No personal information is needed to join.</p> */}
                            <ul className="list-info">
                                <li>
                                    <div className="d-flex align-items-center">
                                        <img src={Images.tick} className="img-fluid" alt="img" />
                                        No personal information is needed to join.
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <img src={Images.tick} className="img-fluid" alt="img" />
                                        An active game is required for our servers to communicate through our software development kit (SDK.
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <img src={Images.tick} className="img-fluid" alt="img" />
                                        Integration videos, documents and technical support are readily available.
                                    </div>
                                </li>
                                {/* <li>
                                    <div className="d-flex align-items-center">
                                        <img src={Images.tick} className="img-fluid" alt="img" />
                                        Sed et nisi id diam condimentum porttitor
                                    </div>
                                </li> */}
                            </ul>
                        </div>
                    </Col>
                    <Col lg="5" className="order-lg-2 order=1">
                        <video width="100%" height="100%" poster={Images.videohowitworks}>
                            <source src="movie.mp4" type="video/mp4" />
                            <source src="movie.ogg" type="video/ogg" />
                            Your browser does not support the video tag.
                        </video>
                    </Col>
                </Row>
                <Row className=" align-items-center">
                    <Col lg="5">
                        <video width="100%" height="100%" poster={Images.videohowGetPaid}>
                            <source src="movie.mp4" type="video/mp4" />
                            <source src="movie.ogg" type="video/ogg" />
                            Your browser does not support the video tag.
                        </video>
                    </Col>
                    <Col lg="7" className="d-flex justify-content-lg-end mt-lg=0 mt-4">
                        <div className="program-inner-content">
                            <h2>How You Get Paid</h2>
                            {/* <p>As a developer, you enjoy a 60/40 revenue split — you get 60%. </p> */}
                            <ul className="list-info">
                                <li>
                                    <div className="d-flex align-items-center">
                                        <img src={Images.tick} className="img-fluid" alt="img" />
                                        As a developer, you enjoy a 60/40 revenue split — you get 60%.
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <img src={Images.tick} className="img-fluid" alt="img" />
                                        You generate revenues from the entry fees of players using your gaming app, collected by our platform.
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <img src={Images.tick} className="img-fluid" alt="img" />
                                        Get paid out in $USDT on your dashboard, withdrawing directly to your MetaMask wallet.
                                    </div>
                                </li>
                                {/* <li>
                                    <div className="d-flex align-items-center">
                                        <img src={Images.tick} className="img-fluid" alt="img" />
                                        Sed et nisi id diam condimentum porttitor
                                    </div>
                                </li> */}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default DevelopersHowWorks;
