import React , {useState , useEffect} from "react";
import {Images} from "../../assets/asset";
import Slider from "react-slick";
import { Link, useNavigate  } from "react-router-dom";
import {Container} from "react-bootstrap";
import { ENV } from "./../../config/config";

var slider2settings = {
    dots: false,
    arrows: false,
    infinite: false,
    loop:true,
    initialSlide: 0,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,

    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};
function GamesDetailSlider2(props) {
    const navigate = useNavigate()

    // const 

    return (
        <div className="game-detail-slider2">
            <Container fluid className="custom-container">
                <div className="d-flex heading-wraper">
                    <h3 className="common-heading">You May Also Like</h3>
                </div>
                <div className="explore-games-slider">
                    <Slider {...slider2settings}>
                        {props.games.map((game, index) => {
                            return(
                            <div className="explore-games-card" key={index}>
                                <div className="explore-games-image-container">
                                    <img src={game.icon} className="img-fluid" alt="img" />
                                </div>
                                <div className="bottom-content">
                                    <h4 className="black-text">{game.name}</h4>
                                    <p>{game.tagLine}</p>
                                    <div className="d-flex">
                                        <div className="d-flex">
                                            <Link to={`/games-detail/${game._id}`} className="small-common-btn hover-effect btn btn-playgame">
                                            {/* <p onClick={() => {navigate(`/games-detail/${game._id}`)}} className="small-common-btn hover-effect btn btn-playgame"> */}
                                                <span>Play</span>
                                            {/* </p> */}
                                            </Link>
                                        </div>
                                        <div className="d-flex align-items-center ms-4 flex-fill">
                                            {(game.platformType == 1 || game.platformType == 3) && game.androidPackageName ?
                                            <a href={ENV.playStoreBundleUrl+game.androidPackageName}  target="_blank">
                                                <img src={Images.blackAndroid} className="img-fluid" alt="img" />
                                            </a>
                                            :''}
                                            {(game.platformType == 2 || game.platformType == 3) && game.iOSStoreLink  ?
                                            <a href={game.iOSStoreLink}  target="_blank">
                                                <img src={Images.blackApple} className="img-fluid ms-4" alt="img" />
                                            </a>
                                            :''}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            )

                        })}
                    </Slider>
                </div>
            </Container>
        </div>
    );
}

export default GamesDetailSlider2;
