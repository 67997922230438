import {Container, Row, Col} from "react-bootstrap";
import {Images} from "../../../assets/asset";
import { Link } from "react-router-dom";
import "./LoyalityProgram.css";
import { ENV } from "./../../../config/config";

function LoyalityProgram() {
    return (
        <>
            {/* <section className="loyality-program">
                <Container fluid className="custom-container">
                    <Row className="align-items-center">
                        <Col lg="8">
                            <div className="program-inner-content">
                                <h2>Loyalty Program</h2>
                                <p>Etiam tincidunt vitae purus ac tempus. Ut tempor cursus velit at dignissim. Aenean efficitur arcu risus, ac interdum enim interdum non. Suspendisse ornare augue libero, vel lobortis libero pretium vitae.</p>
                                <ul className="list-info">
                                    <li>
                                        <div className="d-flex align-items-center">
                                            <img src={Images.tick} className="img-fluid" alt="img" />
                                            Etiam ac nunc tempus, accumsan velit vitae, tristique nulla
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex align-items-center">
                                            <img src={Images.tick} className="img-fluid" alt="img" />
                                            Integer vestibulum metus a massa tristique consectetur
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex align-items-center">
                                            <img src={Images.tick} className="img-fluid" alt="img" />
                                            Sed et nisi id diam condimentum porttitor
                                        </div>
                                    </li>
                                </ul>
                                <div className="btn-holder d-flex lets-begin-game">
                                    <a className="hover-effect btn btn-playgame" href={`${ENV.deskillzPlayerSiteUrl}/login`} target="_blank">
                                        <span>Let the Game Begin</span>
                                    </a>
                                </div>
                            </div>
                        </Col>
                        <Col lg="4" className="d-flex justify-content-lg-start justify-content-center mt-lg-0 mt-md-5 mt-3">
                            <div className="loyality-image">
                                <img src={Images.loyalitypg} className="img-fluid" alt="img" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section> */}
        </>
    );
}

export default LoyalityProgram;
