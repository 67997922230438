import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Images } from "../../../assets/asset";
import { connect } from 'react-redux'
import Slider from "react-slick";
import Lottie from "react-lottie-player";
import lottieJsonicon from "../../../assets/lf20_4amebfkg.json";

import video from "./../../../assets/video/video.mp4";
import { Link } from "react-router-dom";
import "./ReadyPlay.css";
import { ENV } from "./../../../config/config";
import { beforeGames, getGames } from '../../GamesDetail/Game.action'

function ReadyPlay(props) {
    var settings = {
        className: "center",
        centerMode: true,
        dots: false,
        loop: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2500,
        prevArrow: <BannerPrevArrow />,
        nextArrow: <BannerNextArrow />,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            }
        ],
    };
    const [games, setGames] = useState([])
    const [newLoading, setNewLoading] = useState(true)

    useEffect(() => {
        setNewLoading(true)
        props.getGames()
    }, [])

    useEffect(() => {
        if (props.game.getGames) {
            setGames(props.game.games.games)
            setNewLoading(false)
        }
    }, [props.game.getGames])

    function BannerNextArrow(props) {
        const { onClick } = props;
        return (
            <div onClick={onClick} className="sliderRightArrow">
                <span onClick={onClick} className="slick-arrow slick-next" />
            </div>
        );
    }
    function BannerPrevArrow(props) {
        const { onClick } = props;
        return (
            <div onClick={onClick} className="sliderLeftArrow">
                <span onClick={onClick} className="slick-arrow slick-prev" />
            </div>
        );
    }

    return (
        <>
            <section className="start-to-play">
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className=" start-heading mb-3">
                                <h3 className="common-heading text-center text-black">Start to Play!</h3>
                                <p className="paragraph-text-2">
                                    <span className="red-text">No personal information required.
                                        <a href={`${ENV.deskillzPlayerSiteUrl}/signup`} target="_blank"> Create your account now. </a>
                                    </span>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="ready-for-play">
                <Container fluid className=" custom-container">
                    <Row className="justify-content-center align-items-center">
                        <Col md="7">
                            <div className="play-detail">
                                <div>
                                    <h3 className="common-heading">Win Cash Prizes</h3>
                                    <p className="paragraph-text-2">
                                        <span className="text-white">Level up and release your inner abilities. Challenge other gamers playing any of our popular game titles to win cash prizes.
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className="slider-main-container ready-play-slick-slider">
                            <Slider {...settings}>
                                {
                                    games && games.length > 0 ?
                                        games.map((game, index) => {
                                            return (
                                                <div className="img-contain align-top">
                                                    <div className="video-container">
                                                        <video loop={true} autoPlay={true} muted={true} controls={false} src={game.homeSliderVideo ? game.homeSliderVideo : video} onError={(e) => { e.target.onerror = null; e.target.src = video }} width="100%" height="100%"></video>
                                                    </div>
                                                    <div className="slider-image">
                                                        <img src={game.homeSliderImage ? game.homeSliderImage : Images.Game3} onError={(e) => { e.target.onerror = null; e.target.src = Images.Game3 }} alt="img" />
                                                    </div>
                                                    <div className="slide-content">
                                                        <h4>{game?.name}</h4>
                                                        <p>{game?.name}</p>
                                                        <Lottie loop animationData={lottieJsonicon} play style={{ width: 90, height: 90, speed: 1 }} />
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : ''
                                }

                            </Slider>
                        </div>
                    </Row>
                    <div className="playmode-smart-contract-section">
                        <Row>
                            <Col lg="4">
                                {/* <div className="play-mode">
                                    <h3>Play Mode</h3>
                                    <ul className="play--mode--list">
                                        <li>
                                            <div className="d-flex align-items-center">
                                                <img src={Images.smalltick} className="img-fluid" alt="img" />
                                                Using crypto busd to enter into a challenge
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex align-items-center">
                                                <img src={Images.smalltick} className="img-fluid" alt="img" />
                                                Player will be paid out in busd tokens
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex align-items-center">
                                                <img src={Images.smalltick} className="img-fluid" alt="img" />
                                                In practice mode players can play the game for fun to understand the game play
                                            </div>
                                        </li>
                                    </ul>
                                </div> */}
                            </Col>
                            <Col lg="4" className="d-flex justify-content-center align-items-center">
                                <div className="d-flex play--mode-button mt-lg-0 mt-4 mb-sm-0 mb-4">
                                    <Link className="btn hover-effect btn btn-playgame mt-lg-5" to={`explore-games`} target="_blank">
                                        <span>Let’s Play</span>
                                    </Link>
                                </div>
                            </Col>
                            <Col lg="4" className="d-flex justify-content-lg-end">
                                {/* <div className="play-mode">
                                    <h3>Smart Contract</h3>
                                    <ul className="play--mode--list">
                                        <li>
                                            <div className="d-flex align-items-center">
                                                <img src={Images.smalltick} className="img-fluid" alt="img" />
                                                Need smart contract to pay out players the winnings
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex align-items-center">
                                                <img src={Images.smalltick} className="img-fluid" alt="img" />
                                                Refunds when a challenge is not fulfilled
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex align-items-center">
                                                <img src={Images.smalltick} className="img-fluid" alt="img" />
                                                Allocate the loyalty points that can be claim as busd
                                            </div>
                                        </li>
                                    </ul>
                                </div> */}
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    );
}
const mapStateToProps = state => ({
    game: state.game,
    error: state.error,
});
export default connect(mapStateToProps, { beforeGames, getGames })(ReadyPlay);
