// importing layout
import Layout1 from "./layouts/layout_1";

// importing all the themes
import AboutUs from "./pages/AboutUs/AboutUs";
import Contact from "./pages/Contact/Contact";
import Developers from "./pages/Developers/Developers";
import ExploreGames from "./pages/ExploreGames/ExploreGames";
import Faqs from "./pages/Faqs/Faqs";
import GamesDetail from "./pages/GamesDetail/GamesDetail";
import GamesList from "./pages/GamesList/GamesList"
import ExploreGenres from "./pages/ExploreGenres/ExploreGenres";
import GenreDetail from "./pages/ExploreGenres/GenreDetail"
import Home from "./pages/Home/home";
import LatestNews from "./pages/LatestNews/LatestNews";
import Players from "./pages/Players/Players"; 
import Cms from "./pages/Cms/cms"; 
import GenreGames from "./pages/ExploreGames/GenreGames";
const routes = [
    {
        path: "/",
        layout: Layout1,
        access: true,
        exact: true,
        component: Home,
    },
    {
        path: "/developers",
        layout: Layout1,
        access: true,
        exact: true,
        component: Developers,
    },
    {
        path: "/explore-games",
        layout: Layout1,
        access: true,
        exact: true,
        component: ExploreGames,
    },
    {
        path: "/genre-games/:genreId",
        layout: Layout1,
        access: true,
        exact: true,
        component: GenreGames,
    },
    {
        path: "/games-detail/:gameId",
        layout: Layout1,
        access: true,
        exact: true,
        component: GamesDetail,
    },
    {
        path: "/players",
        layout: Layout1,
        access: true,
        exact: true,
        component: Players,
    },
    {
        path: "/about",
        layout: Layout1,
        access: true,
        exact: true,
        component: AboutUs,
    },
    {
        path: "/news",
        layout: Layout1,
        access: true,
        exact: true,
        component: LatestNews,
    },
    {
        path: "/news/:Id",
        layout: Layout1,
        access: true,
        exact: true,
        component: LatestNews,
    },
    {
        path: "/games",
        layout: Layout1,
        access: true,
        exact: true,
        component: GamesList,
    },
    {
        path: "/explore-genres",
        layout: Layout1,
        access: true,
        exact: true,
        component: ExploreGenres,
    },
    {
        path: "/genres/:Id",
        layout: Layout1,
        access: true,
        exact: true,
        component: GenreDetail,
    },
    {
        path: ":type/faqs",
        layout: Layout1,
        access: true,
        exact: true,
        component: Faqs,
    },
    {
        path: "/contact",
        layout: Layout1,
        access: true,
        exact: true,
        component: Contact,
    },
    {
        path: "/cms",
        layout: Layout1,
        access: true,
        exact: true,
        component: Cms,
    },
    {
        path: "/cms/:slug",
        layout: Layout1,
        access: true,
        exact: true,
        component: Cms,
    },
 
];

export default routes;