import { combineReducers } from 'redux'
import errorReducer from './shared/error/error.reducer'
import FaqsReducer from '../pages/Faqs/Faqs.reducer'
import ContactReducer from '../pages/Contact/Contact.reducer'
import LatestNewsReducer from '../pages/LatestNews/LatestNews.reducer'
import FooterReducer from '../pages/Home/footer/footer.reducer'
import CmsReducer from '../pages/Cms/cms.reducer'
import GameReducer from '../pages/GamesDetail/Game.reducer'
import whatscoming from '../pages/Home/WhatsComing/WhatsComing.reducer'
export default combineReducers({
    error: errorReducer,
    faqs:FaqsReducer,
    contacts:ContactReducer,
    news:LatestNewsReducer,
    footer:FooterReducer,
    cms_page:CmsReducer,
    game:GameReducer,
    whatscoming:whatscoming

})