import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import AboutBanner from "./AboutBanner";
import LatestDeskillz from "./LatestDeskillz";
import AboutDeskillzPartnership from "./AboutDeskillzPartnership";
import { getAllNews } from '../LatestNews/LatestNews.action';
import { ENV } from '../../config/config';
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import { Helmet } from "react-helmet";


function AboutUs(props) {
    const [news, setNews] = useState(null)
    const [loader, setLoader] = useState(false)
    useEffect(() => {
        window.scroll(0, 0)
        props.getAllNews()
    }, [])

    useEffect(() => {
        if (props.news.news) {
            setLoader(false)
            setNews(props.news.news)
        }
    }, [props.news.news])

    return (
        <>
            <Helmet>
                <title>About | Deskillz | Esports, Mobile Gaming Tournament</title>
            </Helmet>
            {
                loader ?
                    <FullPageLoader />
                    :
                    <>
                        <AboutBanner />
                        <LatestDeskillz news={news} />
                        <AboutDeskillzPartnership />
                    </>

            }
        </>

    );
}
const mapStateToProps = state => ({
    news: state.news,
    error: state.error,
});
export default connect(mapStateToProps, { getAllNews })(AboutUs);
