import { toast } from 'react-toastify';
import axios from 'axios'
import { GET_ERRORS, BEFORE_CMS_PAGE,GET_CMS_PAGE, GET_CMS , GET_CMS_PAGES} from '../../redux/types';
import { emptyError } from '../../redux/shared/error/error.action';
import { ENV } from '../../config/config';

export const beforeCms = () => {
    return {
        type: BEFORE_CMS_PAGE
    }
}

export const getCmsPages = (body) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}cms/get-cms-page`;
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data.success) {
            // toast.success(data.message)
            dispatch({
                type: GET_CMS_PAGE,
                payload: data.cms
            })
        } else {
            // toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data.data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const getAllCms =(qs) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}cms/`; 

    fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
        },
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: GET_CMS_PAGES,
                payload: data.data
            })
        } else {
            // toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data.data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })   
}



