
import {GET_CMS, GET_FOOTER, BEFORE_FOOTER} from '../../../redux/types'
const initialState = { footer:null, cms:null, getcms:false, getfooter:false }

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_FOOTER:
            return {...state, footer: action.payload, getfooter: true}
       
        case GET_CMS:
            return {...state, cms: action.payload, getcms: true,  }
               
        case BEFORE_FOOTER:
            return {...state, getfooter:false, getcms:false }
            
        default:
            return {...state }
    }
}