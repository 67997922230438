import React, { useState, useEffect } from "react";
import Header from "../pages/Home/header/header";
import Footer from "../pages/Home/footer/footer";


class Layout1 extends React.Component {
    componentWillReceiveProps(nextProps) {
        window.scroll(0, 0)
    }
    render() {

        const { children } = this.props;
        return (
            <div className="main-wrapper">
                <Header />
                <div>
                    {children}
                </div>
                <Footer />
            </div>
        )
    }

}
export default Layout1;