import React, { useState, useEffect } from "react";
import { Images } from "../../assets/asset";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import { beforeGames, getPopularGames } from '../GamesDetail/Game.action'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

var popularsettings = {
    dots: true,
    arrows: false,
    loop: true,
    initialSlide: 0,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    prevArrow: <BannerPrevArrow />,
    nextArrow: <BannerNextArrow />,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: false,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                dots: false
            },
        },
    ],
};

function BannerNextArrow(props) {
    const { onClick } = props;
    return (
        <div onClick={onClick} className="sliderRightArrow">
            <span onClick={onClick} className="slick-arrow slick-next" />
        </div>
    );
}
function BannerPrevArrow(props) {
    const { onClick } = props;
    return (
        <div onClick={onClick} className="sliderLeftArrow">
            <span onClick={onClick} className="slick-arrow slick-prev" />
        </div>
    );
}
function MostPopularSlider(props) {
    const [games, setGames] = useState([])
    const [popularLoading, setPopularLoading] = useState(true)
    useEffect(() => {
        setPopularLoading(true)
        props.getPopularGames()
    }, [])

    useEffect(() => {
        if (props.game.getPopularGames) {
            setGames(props.game.popularGames)
            setPopularLoading(false)
        }
    }, [props.game.getPopularGames])
    return (
        <div className="most-popular-section common-dot-sliderbtn">
            <div className="d-flex heading-wraper">
                <h3 className="common-heading">Most Popular</h3>
            </div>
            {popularLoading
                ?
                <div className="game-featured-slider common-dot-sliderbtn feature-games-slider">
                    <Slider {...popularsettings}>
                        {
                            // [0,1,2,3].map((seketon, index)=>{
                            // return( key={index}
                            <div className="most-popular-card" >
                                <div className="most-popular-image-container">
                                    {/* <Skeleton circle={true} width={100} height={100} /> */}
                                </div>
                                <div className="bottom-profile-content d-flex">
                                    <div className="profile-info d-flex flex-column justify-content-center">
                                        <SkeletonTheme color="#202020" highlightColor="#d31d05">
                                            <Skeleton count={2} enableAnimation={true} />
                                            <Skeleton count={2} />
                                        </SkeletonTheme>
                                    </div>
                                </div>
                            </div>
                            // )
                            // }
                            // )
                        }
                    </Slider>
                </div>
                :
                <div className="most-popular-slider palyer-site feature-games-slider">
                    <Slider {...popularsettings}>
                        {games && games.length > 0 ?
                            games.map((game, index) => {
                                return (
                                    <Link to={`/games-detail/${game?._id}`} className="most-popular-card" key={index}>
                                        {/* <div className="most-popular-card" key={index}> */}
                                        <div className="most-popular-image-container expl-mst-pop-img-cont">
                                            <img src={game?.backgroundImage ? game?.backgroundImage : Images.mostpopularimg1} alt={game?.name + '| Games to Earn'} />
                                        </div>
                                        <div className="bottom-profile-content d-flex">
                                            <div className="image-box d-flex">
                                                <img src={game?.icon ? game?.icon : Images.profilepopularimg1} className="img-fluid" alt={game?.name + '| Crypto Earn '} />
                                            </div>
                                            <div className="profile-info d-flex flex-column justify-content-center">
                                                <h5>{game?.name}</h5>
                                                <p>{game?.tagLine}</p>
                                            </div>
                                        </div>
                                        {/* </div> */}
                                    </Link>
                                )
                            })
                            : ''
                        }
                    </Slider>
                </div>
            }
        </div>
    );
}

const mapStateToProps = state => ({
    game: state.game,
    error: state.error,
});

export default connect(mapStateToProps, { beforeGames, getPopularGames })(MostPopularSlider);
