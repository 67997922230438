
import {BEFORE_FAQS,GET_FAQS} from '../../redux/types'
const initialState = { faqs:null, getfaqs:false }

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_FAQS:
            return { ...state, faqs: action.payload, getfaqs: true }
       
        case BEFORE_FAQS:
            return { ...state, getfaqs:false }
            
        default:
            return { ...state }
    }
}