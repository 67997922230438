import { Container } from "react-bootstrap";
import FeaturedGameSlider from "./FeaturedGameSlider";
import MostPopularSlider from "./MostPopularSlider";
import NewGameSlider from "./NewGameSlider";
import ExploreGamesSlider from "./ExploreGamesSlider";
import "./ExploreGames.css";
import { Helmet } from "react-helmet";

function ExploreGames() {
    return (
        <>
            <Helmet>
                <title>Mobile Games to Earn Crypto | Deskillz</title>
                <meta name="keywords" content="Mobile Games to Earn Crypto, Crypto Games to Earn" />
                <meta name="description" content="Enjoy our featured games, our selection of the most popular and new games in different genres, play mobile games to earn crypto and rewards! Join the fun now!" />
                <link rel="canonical" href="https://www.deskillz.games/explore-games" />
            </Helmet>
            <div className="feature-pg">
                <Container fluid className="custom-container">
                    <div className="text-center my-3">
                        <h1 className="fs-1">Mobile Games To Earn Crypto</h1>
                    </div>
                    <FeaturedGameSlider />
                    <MostPopularSlider />
                    <NewGameSlider />
                    <ExploreGamesSlider />
                </Container>
            </div>
        </>
    );
}

export default ExploreGames;
