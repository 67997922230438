
import {BEFORE_CONTACT_US,GET_CONTACT_US} from '../../redux/types'
const initialState = { contacts:null,  getcontacts:false }

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CONTACT_US:
            return {...state, contacts: action.payload, getcontacts: true }
       
        case BEFORE_CONTACT_US:
            return {...state, getcontacts:false }
            
        default:
            return { ...state }
    }
}