import { Container, Row, Col } from "react-bootstrap";
import { Images } from "../../assets/asset";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { ENV } from "./../../config/config";
import "../../pages/Home/WhatsComing/WhatsComing.css";

var LatestDeskillzsettings = {
    dots: false,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    infinite: false,
    loop: true,
    initialSlide: 0,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2500,
    prevArrow: <BannerPrevArrow />,
    nextArrow: <BannerNextArrow />,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 1,
                infinite: true,
                dots: false,
                arrows: true,
            },
        },
    ],
};
function BannerNextArrow(props) {
    const { onClick } = props;
    return (
        <div onClick={onClick} className="sliderRightArrow">
            <span onClick={onClick} className="slick-arrow slick-next" />
        </div>
    );
}
function BannerPrevArrow(props) {
    const { onClick } = props;
    return (
        <div onClick={onClick} className="sliderLeftArrow">
            <span onClick={onClick} className="slick-arrow slick-prev" />
        </div>
    );
}

function LatestDeskillz(props) {
    return (
        <>
            <section className="start-to-play">
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className=" start-heading mb-3">
                                <h3 className="common-heading text-center text-black mb-3">Start to Play!</h3>
                                <p className="text-black text-center mb-2">Compete, have fun, and get rewarded. Get paid in $USDT and farm our native $GLHF Tokens through our achievement, loyalty, and MVP programs.</p>
                                <div className="d-sm-flex align-items-center justify-content-center">
                                    <span className="d-block text-center text-black">No Personal information required</span>
                                    <a className="text-center d-block" href={`${ENV.deskillzPlayerSiteUrl}/login`} target="_blank"> &nbsp; Create your account now. </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default LatestDeskillz;
