import React from "react";
import { Link } from "react-router-dom";
import { Images } from "../../assets/asset";
import { Container, Row, Col } from "react-bootstrap";
function ExploreGenres(props) {
	return (
		<div className="feature-pg all-games-listing">
			<Container fluid className="custom-container">
				<div className="mb-4 mb-md-5">
					<h1 className="section-heading">Games List</h1>
				</div>
				<Row>
					<Col xl={3} lg={4} md={6}>
						<div className="d-flex featured-card">
							<div className="d-flex card-left-content">
								<div className="image-outer-container">
									{/* <img src={game?.icon ? game?.icon : Images.soldier1} className="img-fluid" alt="img" /> */}
									<img src="https://via.placeholder.com/150" alt="" />	
								</div>
							</div>
							<div className="d-flex flex-column card-right-content ">
								<div className="card-heading">
									<h4>Game Name</h4>
								</div>
								<div className="ft-common--text">
									<p>Game Tagline</p>
								</div>
								<div className="game-feature-s-btns d-flex justify-content-between">
									<div className="d-flex me-2">
										<Link to="/games-detail/:Id" className="small-common-btn hover-effect btn btn-playgame">
											<span>Play</span>
										</Link>
									</div>
									<div className="android-apple-btns d-flex justify-content-center align-items-center">
										<img src={Images.androidstore} className="img-fluid" alt="img" />
										<img src={Images.applestore} className="img-fluid ms-3" alt="img" />
									</div>
								</div>
							</div>
						</div>
					</Col>
					<Col xl={3} lg={4} md={6}>
						<div className="d-flex featured-card">
							<div className="d-flex card-left-content">
								<div className="image-outer-container">
									{/* <img src={game?.icon ? game?.icon : Images.soldier1} className="img-fluid" alt="img" /> */}
									<img src="https://via.placeholder.com/150" alt="" />	
								</div>
							</div>
							<div className="d-flex flex-column card-right-content ">
								<div className="card-heading">
									<h4>Game Name</h4>
								</div>
								<div className="ft-common--text">
									<p>Game Tagline</p>
								</div>
								<div className="game-feature-s-btns d-flex justify-content-between">
									<div className="d-flex me-2">
										<Link to="/games-detail/:Id" className="small-common-btn hover-effect btn btn-playgame">
											<span>Play</span>
										</Link>
									</div>
									<div className="android-apple-btns d-flex justify-content-center align-items-center">
										<img src={Images.androidstore} className="img-fluid" alt="img" />
										<img src={Images.applestore} className="img-fluid ms-3" alt="img" />
									</div>
								</div>
							</div>
						</div>
					</Col>
					<Col xl={3} lg={4} md={6}>
						<div className="d-flex featured-card">
							<div className="d-flex card-left-content">
								<div className="image-outer-container">
									{/* <img src={game?.icon ? game?.icon : Images.soldier1} className="img-fluid" alt="img" /> */}
									<img src="https://via.placeholder.com/150" alt="" />	
								</div>
							</div>
							<div className="d-flex flex-column card-right-content ">
								<div className="card-heading">
									<h4>Game Name</h4>
								</div>
								<div className="ft-common--text">
									<p>Game Tagline</p>
								</div>
								<div className="game-feature-s-btns d-flex justify-content-between">
									<div className="d-flex me-2">
										<Link to="/games-detail/:Id" className="small-common-btn hover-effect btn btn-playgame">
											<span>Play</span>
										</Link>
									</div>
									<div className="android-apple-btns d-flex justify-content-center align-items-center">
										<img src={Images.androidstore} className="img-fluid" alt="img" />
										<img src={Images.applestore} className="img-fluid ms-3" alt="img" />
									</div>
								</div>
							</div>
						</div>
					</Col>
					<Col xl={3} lg={4} md={6}>
						<div className="d-flex featured-card">
							<div className="d-flex card-left-content">
								<div className="image-outer-container">
									{/* <img src={game?.icon ? game?.icon : Images.soldier1} className="img-fluid" alt="img" /> */}
									<img src="https://via.placeholder.com/150" alt="" />	
								</div>
							</div>
							<div className="d-flex flex-column card-right-content ">
								<div className="card-heading">
									<h4>Game Name</h4>
								</div>
								<div className="ft-common--text">
									<p>Game Tagline</p>
								</div>
								<div className="game-feature-s-btns d-flex justify-content-between">
									<div className="d-flex me-2">
										<Link to="/games-detail/:Id" className="small-common-btn hover-effect btn btn-playgame">
											<span>Play</span>
										</Link>
									</div>
									<div className="android-apple-btns d-flex justify-content-center align-items-center">
										<img src={Images.androidstore} className="img-fluid" alt="img" />
										<img src={Images.applestore} className="img-fluid ms-3" alt="img" />
									</div>
								</div>
							</div>
						</div>
					</Col>
					<Col xl={3} lg={4} md={6}>
						<div className="d-flex featured-card">
							<div className="d-flex card-left-content">
								<div className="image-outer-container">
									{/* <img src={game?.icon ? game?.icon : Images.soldier1} className="img-fluid" alt="img" /> */}
									<img src="https://via.placeholder.com/150" alt="" />	
								</div>
							</div>
							<div className="d-flex flex-column card-right-content ">
								<div className="card-heading">
									<h4>Game Name</h4>
								</div>
								<div className="ft-common--text">
									<p>Game Tagline</p>
								</div>
								<div className="game-feature-s-btns d-flex justify-content-between">
									<div className="d-flex me-2">
										<Link to="/games-detail/:Id" className="small-common-btn hover-effect btn btn-playgame">
											<span>Play</span>
										</Link>
									</div>
									<div className="android-apple-btns d-flex justify-content-center align-items-center">
										<img src={Images.androidstore} className="img-fluid" alt="img" />
										<img src={Images.applestore} className="img-fluid ms-3" alt="img" />
									</div>
								</div>
							</div>
						</div>
					</Col>
					<Col xl={3} lg={4} md={6}>
						<div className="d-flex featured-card">
							<div className="d-flex card-left-content">
								<div className="image-outer-container">
									{/* <img src={game?.icon ? game?.icon : Images.soldier1} className="img-fluid" alt="img" /> */}
									<img src="https://via.placeholder.com/150" alt="" />	
								</div>
							</div>
							<div className="d-flex flex-column card-right-content ">
								<div className="card-heading">
									<h4>Game Name</h4>
								</div>
								<div className="ft-common--text">
									<p>Game Tagline</p>
								</div>
								<div className="game-feature-s-btns d-flex justify-content-between">
									<div className="d-flex me-2">
										<Link to="/games-detail/:Id" className="small-common-btn hover-effect btn btn-playgame">
											<span>Play</span>
										</Link>
									</div>
									<div className="android-apple-btns d-flex justify-content-center align-items-center">
										<img src={Images.androidstore} className="img-fluid" alt="img" />
										<img src={Images.applestore} className="img-fluid ms-3" alt="img" />
									</div>
								</div>
							</div>
						</div>
					</Col>
					<Col xl={3} lg={4} md={6}>
						<div className="d-flex featured-card">
							<div className="d-flex card-left-content">
								<div className="image-outer-container">
									{/* <img src={game?.icon ? game?.icon : Images.soldier1} className="img-fluid" alt="img" /> */}
									<img src="https://via.placeholder.com/150" alt="" />	
								</div>
							</div>
							<div className="d-flex flex-column card-right-content ">
								<div className="card-heading">
									<h4>Game Name</h4>
								</div>
								<div className="ft-common--text">
									<p>Game Tagline</p>
								</div>
								<div className="game-feature-s-btns d-flex justify-content-between">
									<div className="d-flex me-2">
										<Link to="/games-detail/:Id" className="small-common-btn hover-effect btn btn-playgame">
											<span>Play</span>
										</Link>
									</div>
									<div className="android-apple-btns d-flex justify-content-center align-items-center">
										<img src={Images.androidstore} className="img-fluid" alt="img" />
										<img src={Images.applestore} className="img-fluid ms-3" alt="img" />
									</div>
								</div>
							</div>
						</div>
					</Col>
					<Col xl={3} lg={4} md={6}>
						<div className="d-flex featured-card">
							<div className="d-flex card-left-content">
								<div className="image-outer-container">
									{/* <img src={game?.icon ? game?.icon : Images.soldier1} className="img-fluid" alt="img" /> */}
									<img src="https://via.placeholder.com/150" alt="" />	
								</div>
							</div>
							<div className="d-flex flex-column card-right-content ">
								<div className="card-heading">
									<h4>Game Name</h4>
								</div>
								<div className="ft-common--text">
									<p>Game Tagline</p>
								</div>
								<div className="game-feature-s-btns d-flex justify-content-between">
									<div className="d-flex me-2">
										<Link to="/games-detail/:Id" className="small-common-btn hover-effect btn btn-playgame">
											<span>Play</span>
										</Link>
									</div>
									<div className="android-apple-btns d-flex justify-content-center align-items-center">
										<img src={Images.androidstore} className="img-fluid" alt="img" />
										<img src={Images.applestore} className="img-fluid ms-3" alt="img" />
									</div>
								</div>
							</div>
						</div>
					</Col>
					<Col xl={3} lg={4} md={6}>
						<div className="d-flex featured-card">
							<div className="d-flex card-left-content">
								<div className="image-outer-container">
									{/* <img src={game?.icon ? game?.icon : Images.soldier1} className="img-fluid" alt="img" /> */}
									<img src="https://via.placeholder.com/150" alt="" />	
								</div>
							</div>
							<div className="d-flex flex-column card-right-content ">
								<div className="card-heading">
									<h4>Game Name</h4>
								</div>
								<div className="ft-common--text">
									<p>Game Tagline</p>
								</div>
								<div className="game-feature-s-btns d-flex justify-content-between">
									<div className="d-flex me-2">
										<Link to="/games-detail/:Id" className="small-common-btn hover-effect btn btn-playgame">
											<span>Play</span>
										</Link>
									</div>
									<div className="android-apple-btns d-flex justify-content-center align-items-center">
										<img src={Images.androidstore} className="img-fluid" alt="img" />
										<img src={Images.applestore} className="img-fluid ms-3" alt="img" />
									</div>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
}
export default ExploreGenres;