import React, { useState, useEffect }  from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Images} from "../../assets/asset";
import "./ExploreGames.css";
import {connect} from 'react-redux'
import { beforeGames, getGenreGames , getGenre} from '../GamesDetail/Game.action'
import { useParams } from 'react-router';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

function GenreGames(props) {
    const { genreId } = useParams()
    const [games, setGames] = useState([])
    const [genreLoading, setGenreLoading] = useState(true)
    const [genre , setGenre] = useState({})
    useEffect(() => {

        setGenreLoading(true)
        props.getGenreGames(genreId)
        props.getGenre(genreId)
        console.log("genre: ",genreId)

    }, [])
    
    useEffect(() => {
        if(props.game.getGenreGamesAuth){
            setGames(props.game.games)
            setGenreLoading(false)
            props.beforeGames()
        }
    }, [props.game.getGenreGamesAuth])

    useEffect(()=>{
        if(props.game.getGenreAuth){
            let { genre } = props.game
            setGenre(genre)
        }

    },[props.game.getGenreAuth])

    return (
        <>
            <div className="feature-pg">
                <Container fluid className="custom-container">
                    <div className="d-flex">
                        <Link to="/explore-games" className="back-btn">
                            <FontAwesomeIcon icon={faArrowLeft} className="me-3" />
                            Back
                        </Link>
                    </div>
                    <div className="most-popular-section common-dot-sliderbtn">
                        <div className="d-flex heading-wraper">
                            <h3 className="common-heading">Genre - {genre?.name ? genre?.name : 'N/A'}</h3>
                        </div>
                        <Row>
                            {genreLoading 
                                ?
                                [0,1,2,3].map((seketon, index)=>{
                                    return(
                                        <Col md={3} className="mb-2" key={index}>
                                            <Link to='' className="most-popular-card">
                                                <div className="most-popular-card">
                                                        <SkeletonTheme color="#202020" highlightColor="#d31d05">
                                                            <Skeleton count={2}  />
                                                            <Skeleton count={2}  />
                                                            <Skeleton count={2}  />
                                                        </SkeletonTheme>
                                                </div>
                                            </Link>

                                        </Col>
                                    )
                                })
                                :
                                games && games.length > 0 ? 
                                    games.map((game, index) => {
                                        return (
                                            <Col md={3} className="mb-2" key={index}>
                                                <Link to={`/games-detail/${game?._id}`} className="most-popular-card">
                                                    <div className="most-popular-card">
                                                        <div className="most-popular-image-container">
                                                            <img src={game?.backgroundImage ? game?.backgroundImage : Images.mostpopularimg1} className="img-fluid" alt="img" />
                                                        </div>
                                                        <div className="bottom-profile-content d-flex">
                                                            <div className="image-box d-flex">
                                                                <img src={game?.icon ? game?.icon : Images.profilepopularimg1} className="img-fluid" alt="img" />
                                                            </div>
                                                            <div className="profile-info d-flex flex-column justify-content-center">
                                                                <h5>{game?.name}</h5>
                                                                <p>{game?.tagLine}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>

                                            </Col>
                                        )
                                    })
                                : 'No Games Found'
                            }
                        </Row>
                    </div>
                    
                </Container>
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    game: state.game,
    error: state.error,
});

export default connect(mapStateToProps, {beforeGames, getGenreGames , getGenre })(GenreGames);