import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate, Route, Routes } from "react-router-dom";
import NotFound from "./pages/NotFound/NotFound";
import CookieConsent from "react-cookie-consent";
import routes from './routes'

function App(props) {
  const navigation = useNavigate();
  return (
    <>
      <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
      <Routes>
        {
          routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                element={
                  <route.layout {...props} title={route.title}>
                    <route.component {...props} navigation={navigation} />
                  </route.layout>
                }
              />
            )
          })  
        }
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
