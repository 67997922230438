import React, { useEffect, useState } from "react";
import { getCmsPages, getAllCms, beforeCms } from './cms.action';
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { ENV } from "../../config/config";
import Pagination from "rc-pagination";
import localeInfo from 'rc-pagination/lib/locale/en_US';
import defaultimg from "../../assets/images/defaultImg.png";
import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import FullPageLoader from '../../components/FullPageLoader/FullPageLoader'
import 'react-loading-skeleton/dist/skeleton.css'
import './cms.css'
import { Helmet } from "react-helmet";

function CMS(props) {

    const { slug } = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const [cmsPages, setCmsPages] = useState([])
    const [cms, setCms] = useState({})
    const [pagination, setPagination] = useState({})
    const [Page, setPage] = useState(1)
    useEffect(() => {
        props.beforeCms()
        const qs = ENV.objectToQueryString({ page: 1, limit: 10 })
        props.getAllCms(qs)
    }, [])

    useEffect(() => {
        if (slug) {
            setIsLoading(true)
            props.getCmsPages({ slug: slug })
        } else {
            const qs = ENV.objectToQueryString({ page: 1, limit: 10 })
            props.getAllCms(qs)
        }
    }, [slug])


    useEffect(() => {
        if (props.cms.getcms) {
            const { cms } = props.cms
            setCms(cms)
            props.beforeCms()
            setIsLoading(false)
        }
    }, [props.cms.getcms])

    useEffect(() => {
        if (props.cms.getCmsPagesAuth) {
            const { cms, pagination } = props.cms
            setCmsPages(cms)
            setPagination(pagination)
            setIsLoading(false)
        }
    }, [props.cms.getCmsPagesAuth])


    const onPageChange = (page) => {
        setPage(page)
        const qs = ENV.objectToQueryString({ page, limit: 10 })
        props.getCmsPages(qs)
    }

    return (
        <>
            <Helmet>
                <title>News | Deskillz | Esports, Mobile Gaming Tournament</title>
            </Helmet>
            <div className="news-bg">
                {isLoading ?
                    <FullPageLoader />
                    :
                    <>
                        <Container fluid className="custom-container abc">
                            {
                                !slug ?
                                    <>
                                        <div className="develper-banner-content">
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                <h1 className="subpg-comon-heading">LATEST NEWS</h1>
                                                <div className="text-wraper">
                                                    {/* <p className="subpg-comon-text text-center">
                                                        LATEST Blogs
                                                    </p> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="our-values">
                                            <div className="d-flex justify-content-center align-items-center flex-column space-common">
                                                <h3 className="common-heading">What's Comming!</h3>
                                                <p className="paragraph-text-2">
                                                    <span className="red-text">Dive into the latest game and </span> crypto news.
                                                </p>
                                            </div>
                                            <Row >


                                                {cmsPages && cmsPages.length ? cmsPages.map((n, index) => {
                                                    return (
                                                        <Col lg="4" className="mb-3" key={index}>
                                                            <Link to={`/cms/${n.slug}`}>
                                                                <div className="card-contain">
                                                                    <div className="news-cardimage-box">
                                                                        <img src={n.thumbnail} onError={(e) => { e.target.onerror = null; e.target.src = defaultimg }} alt="img" />
                                                                    </div>
                                                                    <div className="card-inner-content w-100">
                                                                        <h4 class="h-100">{n.title ? n.title : "N/A"}</h4>
                                                                        {/* <p className="text-restrict" dangerouslySetInnerHTML={{ __html: n.description }}></p> */}
                                                                        <div className="text-restrict">
                                                                            <p className="text-restrict" dangerouslySetInnerHTML={{ __html: n.description }}></p>
                                                                            {/* <p>wdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdawwdawdaw</p> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </Col>
                                                    )
                                                }) : <><p>Not Found</p></>}


                                                {cms && cms.length != 0 &&
                                                    <Pagination
                                                        className="m-3"
                                                        defaultCurrent={1}
                                                        pageSize // items per page
                                                        current={Page > pagination?.pages ? pagination.pages : Page} // current active page
                                                        total={pagination?.pages ? pagination.pages : Page} // total pages
                                                        onChange={onPageChange}
                                                        locale={localeInfo}
                                                    />
                                                }
                                            </Row>
                                        </div>
                                    </>
                                    :

                                    <div className="blog-detail-page">
                                        {Object.keys(cms).length !== 0 ?
                                            <Row>
                                                <Col md={12}>
                                                    <div className="blog-header">
                                                        <h2>{cms?.title}</h2>
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="blog-thumbnail">
                                                        <img src={cms?.thumbnail} onError={(e) => { e.target.onerror = null; e.target.src = defaultimg }} alt="img" />
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="blog-content">
                                                        {cms.description ? <div dangerouslySetInnerHTML={{ __html: cms?.description }}></div> : ""}
                                                        <p>Lorem ipsum dolor sit <Link to="/">Home</Link></p>
                                                        {/* <ul>
                                                            <li>Apple</li>
                                                            <li>Banana</li>
                                                            <li>Orange</li>
                                                            <li>Watermelon</li>
                                                        </ul>
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Header 1</th>
                                                                    <th>Header 2</th>
                                                                    <th>Header 3</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>content 1</td>
                                                                    <td>content 2</td>
                                                                    <td>content 3</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>content 1</td>
                                                                    <td>content 2</td>
                                                                    <td>content 3</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>content 1</td>
                                                                    <td>content 2</td>
                                                                    <td>content 3</td>
                                                                </tr>
                                                            </tbody>
                                                        </table> */}
                                                    </div>
                                                </Col>
                                            </Row>
                                            : <><p>No Such CMS Found</p></>
                                        }
                                    </div>

                            }
                        </Container>

                    </>
                }
            </div>
        </>
    );
}
const mapStateToProps = state => ({
    cms: state.cms_page,
    error: state.error,
});

export default connect(mapStateToProps, { getCmsPages, getAllCms, beforeCms })(CMS);