import { Navbar, Container, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom"
import { Images } from "../../../assets/asset";
import Lottie from "react-lottie-player";
import lottieJson from "../../../assets/lf30_editor_plbjptvg.json";
import lottieJson2 from "../../../assets/lf30_editor_1shvmb4p.json";
import "./header.css";
import { ENV } from "../../../config/config";
import { useEffect, useState } from "react";
function Header() {
    const { pathname } = useLocation()
    const [sticky, setSticky] = useState('')
    const handleScroll = () => {
        const newScrollYPosition = window.pageYOffset;
        if (newScrollYPosition > 50) {
            setSticky(' position-fixed top-0 w-100 sticky')
        } else {
            setSticky('')
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={sticky}>
            <div id="main-header" className={"header custom-container "}>
                <Navbar expand="lg">
                    <Container fluid>
                        <Link className="navbar-brand" to="/">
                            <img src={Images.siteLogo} className="img-fluid sit-main-logo" alt="img" />
                        </Link>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav" className="pages-names">
                            <Nav className="ms-auto">
                                <Link className={`${pathname === '/' ? 'active' : ''}`} to="/">Home</Link>
                                <Link className={`${pathname === '/developers' ? 'active' : ''}`} to="/developers">Developers</Link>
                                <Link className={`${pathname === '/players' ? 'active' : ''}`} to="/players">Players</Link>
                                <Link className={`${pathname === '/about' ? 'active' : ''}`} to="/about">About Us</Link>
                                <Link className={`${pathname === '/cms' ? 'active' : ''}`} to="/cms">News</Link>
                                {/* <Link className={`${pathname === '/news' ? 'active' : ''}`} to="/news">News</Link> */}
                            </Nav>
                        </Navbar.Collapse>
                        <div className="herder-btn-holder">
                            <Nav.Link href="/" className="button-lottie">
                                <Lottie loop animationData={lottieJson} play style={{ width: 175, height: 175 }} />
                                Farming
                            </Nav.Link>
                            <Nav.Link href={`${ENV.deskillzPlayerSiteUrl}/login`} target="_blank">
                                <div className="wallet-box">
                                    <Lottie loop animationData={lottieJson2} play style={{ width: 175, height: 175, speed: 1 }} />
                                </div>
                            </Nav.Link>
                        </div>
                    </Container>
                </Navbar>
            </div>
        </div>
    );
}

export default Header;
