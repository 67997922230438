import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import {Images} from "../../assets/asset";
import {Link} from "react-router-dom";
import Lottie from "react-lottie-player";
import lottieJsonreward from "../../assets/67577";
import "../Home/LoyalityProgram/LoyalityProgram.css";
import "./Players.css";
import { ENV } from "./../../config/config";

function PlayersLoyalityprogram() {
    return (
        <>
            {/* <section className="players-loyality-pg loyality-program">
                <Container fluid className="custom-container">
                    <Row className="align-items-center">
                        <Col lg="8">
                            <div className="program-inner-content">
                                <h2>Loyalty Program</h2>
                                <p>Etiam tincidunt vitae purus ac tempus. Ut tempor cursus velit at dignissim. Aenean efficitur arcu risus, ac interdum enim interdum non. Suspendisse ornare augue libero, vel lobortis libero pretium vitae.</p>
                                <ul className="list-info">
                                    <li>
                                        <div className="d-flex align-items-center">
                                            <img src={Images.tick} className="img-fluid" alt="img" />
                                            Etiam ac nunc tempus, accumsan velit vitae, tristique nulla
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex align-items-center">
                                            <img src={Images.tick} className="img-fluid" alt="img" />
                                            Integer vestibulum metus a massa tristique consectetur
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex align-items-center">
                                            <img src={Images.tick} className="img-fluid" alt="img" />
                                            Sed et nisi id diam condimentum porttitor
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg="4" className="d-flex justify-content-lg-start justify-content-center mt-lg-0 mt-md-5 mt-3">
                            <div className="loyality-image">
                                <img src={Images.loyalitypg} className="img-fluid" alt="img" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="how-it-works top-bottom-same-padding">
                <Container fluid className="custom-container">
                    <Row className="jusify-content-center align-items-center">
                        <Col lg="5">
                            <div className="reward-box d-flex">
                                <Lottie loop animationData={lottieJsonreward} play />
                            </div>
                        </Col>
                        <Col lg="7">
                            <div className="program-inner-content">
                                <h2>Get rewards</h2>
                                <p>Deskillz helps you to publish your games by using our SKD’s. Keep building more mobile games for your customers and grow your revenue.</p>
                                <ul className="list-info">
                                    <li>
                                        <div className="d-flex align-items-center">
                                            <img src={Images.tick} className="img-fluid" alt="img" />
                                            Aenean at leo tellus. Morbi eu leo sapien. Fusce libero dolor
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex align-items-center">
                                            <img src={Images.tick} className="img-fluid" alt="img" />
                                            venenatis eget enim sed, commodo efficitur arcunmyu
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex align-items-center">
                                            <img src={Images.tick} className="img-fluid" alt="img" />
                                            Sed et nisi id diam condimentum porttitor
                                        </div>
                                    </li>
                                </ul>
                                <div className="btn-holder d-flex lets-begin-game">
                                    <Link to="/" className="hover-effect btn btn-playgame" href={`${ENV.deskillzPlayerSiteUrl}/login`} target="_blank">
                                        <span>Let the Game Begin</span>
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section> */}
        </>
    );
}

export default PlayersLoyalityprogram;
