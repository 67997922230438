import React from "react";

import Banner from "./Banner/Banner";
import WelCome from "./WelCome/WelCome";
import GameOwner from "./GameOwner/GameOwner";
import DashboardGaming from "./DashboardGaming/DashboardGaming";
import ReadyPlay from "./ReadyPlay/ReadyPlay";
import LoyalityProgram from "./LoyalityProgram/LoyalityProgram";
import GamingPlatform from "./GamingPlatform/GamingPlatform";
import WhatsComing from "./WhatsComing/WhatsComing";
import { Helmet } from "react-helmet";

function home() {
    return (
        <>
            <Helmet>
                <title>Decentralized Crypto Gaming Platform | Deskillz</title>
            </Helmet>
            <Banner />
            <WelCome />
            <GameOwner />
            <DashboardGaming />
            <ReadyPlay />
            <LoyalityProgram />
            <GamingPlatform />
            {/* <WhatsComing /> */}
        </>
    );
}

export default home;
