import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Images } from "../../assets/asset";
import "./Developers.css";
import { ENV } from "../../config/config";


function DevelopersBanner() {
    return (
        <div className="developer-pg-wraper">
            <div className="developer-pg">
                <div className="develper-banner">
                    <Container fluid className="custom-container">
                        <section className="develper-banner-content">
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <h1 className="subpg-comon-heading">MAKE REVENUE, TRACK YOUR SUCCESS</h1>
                                <div className="text-wraper">
                                    <p className="subpg-comon-text text-center">Deskillz makes integrating your game into our blockchain ecosystem
                                        easy. Benefit from our revenue share model, and track your success with our powerful dashboard.
                                    </p>
                                </div>
                                <div className="btn-holder d-flex justify-content-center flex-column">
                                    <a href={`${ENV.deskillzDeveloperSiteUrl}/login`} target="_blank" className="btn red-btn btn-playgame">
                                        <span>Join Us</span>
                                    </a>
                                </div>
                            </div>
                        </section>
                        <section className="services-area">
                            <div className="d-flex flex-column services-area-content">
                                <h3 className="text-lg-start text-center">Winning Is Easy With Deskillz</h3>
                                {/* <p className="text-lg-start text-center">Vestibulum commodo sapien non elit porttitor, vitae volutpat nibh mollis. Nulla porta risus id neque tempor, in efficitur justo imperdiet.</p> */}
                            </div>
                            <div className="services-detail">
                                <Row className="align-items-lg-start align-items-center">
                                    <Col lg="3" md="6">
                                        <div className="mb-3 mb-lg-0">
                                            <div className="service-box">
                                                <div className="service-image-container">
                                                    <img src={Images.iconIntegrate} className="img-fluid" alt="img" />
                                                </div>
                                                <div className="box-bottom-footer">
                                                    <h4>Integrate</h4>
                                                </div>
                                            </div>
                                            <div className="description text-center">
                                                <p>Software development kit (SDK) provided.</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg="3" md="6">
                                        <div className="mb-3 mb-lg-0">
                                            <div className="service-box">
                                                <div className="service-image-container">
                                                    <img src={Images.iconSupport} className="img-fluid" alt="img" />
                                                </div>
                                                <div className="box-bottom-footer">
                                                    <h4>Support</h4>
                                                </div>
                                            </div>
                                            <div className="description text-center">
                                                <p>We take care of any technical issues.</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg="3" md="6">
                                        <div className="mb-3 mb-lg-0">
                                            <div className="service-box">
                                                <div className="service-image-container">
                                                    <img src={Images.serviceglobal} className="img-fluid" alt="img" />
                                                </div>
                                                <div className="box-bottom-footer">
                                                    <h4>Monitor Success</h4>
                                                </div>
                                            </div>
                                            <div className="description text-center">
                                                <p>Track performance through our dashboard. </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg="3" md="6">
                                        <div className="mb-3 mb-lg-0">
                                            <div className="service-box">
                                                <div className="service-image-container">
                                                    <img src={Images.iconGrow} className="img-fluid" alt="img" />
                                                </div>
                                                <div className="box-bottom-footer">
                                                    <h4>Grow</h4>
                                                </div>
                                            </div>
                                            <div className="description text-center">
                                                <p>Gain exposure through our gaming community.</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </section>
                    </Container>
                </div>
            </div>
        </div>
    );
}

export default DevelopersBanner;
