import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Images } from "../../assets/asset";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { connect } from 'react-redux'
import { beforeGames, getFeaturedGames } from '../GamesDetail/Game.action'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

var settings = {
    className: "center",
    dots: true,
    arrows: false,
    infinite: false,
    loop: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2500,
    pauseOnHover: true,
    prevArrow: <BannerPrevArrow />,
    nextArrow: <BannerNextArrow />,
    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
                dots: true,
                arrows: true,
            },
        },
    ],
};
function BannerNextArrow(props) {
    const { onClick } = props;
    return (
        <div onClick={onClick} className="sliderRightArrow">
            <span onClick={onClick} className="slick-arrow slick-next" />
        </div>
    );
}
function BannerPrevArrow(props) {
    const { onClick } = props;
    return (
        <div onClick={onClick} className="sliderLeftArrow">
            <span onClick={onClick} className="slick-arrow slick-prev" />
        </div>
    );
}
function FeaturedGameSlider(props) {
    const [featuredGames, setFeaturedGames] = useState([])
    const [featuredLoading, setFeaturedLoading] = useState(true)
    useEffect(() => {
        setFeaturedLoading(true)
        props.getFeaturedGames()
    }, [])

    useEffect(() => {
        if (props.game.getFeaturedGames) {
            setFeaturedGames(props.game.featuredGames)
            props.beforeGames()
            setFeaturedLoading(false)
        }
    }, [props.game.getFeaturedGames])


    return (
        <div className="featured-game-slider-section">
            <Row>
                <div className="d-flex heading-wraper">
                    <h3 className="common-heading">
                        Featured Games <Link to="/games" className="explore-more">Explore More</Link>
                    </h3>
                </div>
            </Row>
            {featuredLoading
                ?
                <div className="game-featured-slider common-dot-sliderbtn feature-games-slider">
                    <SkeletonTheme color="#202020" highlightColor="#d31d05">
                        <Slider {...settings}>
                            {
                                [0, 1, 2, 3].map((seketon, index) => {
                                    return (
                                        <div className="d-flex featured-card" key={index}>
                                            <div className="d-flex card-left-content">
                                                <div className="image-outer-container">
                                                    <Skeleton circle={true} width={80} height={80} />
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column card-right-content ">
                                                <div className="card-heading">
                                                    <Skeleton count={3} />
                                                </div>
                                                <div className="ft-common--text">
                                                    <Skeleton count={1} />
                                                </div>

                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Slider>
                    </SkeletonTheme>
                </div>

                :
                <div className="game-featured-slider common-dot-sliderbtn feature-games-slider">
                    {featuredGames && featuredGames.length > 0 ?
                        <Slider {...settings}>
                            {featuredGames.map((game, index) => {
                                return (
                                    <div className="d-flex featured-card">
                                        <div className="d-flex card-left-content">
                                            <div className="image-outer-container">
                                                <img src={game?.icon ? game?.icon : Images.soldier1} className="img-fluid" alt={game?.name + '| Mobile Games to Earn Crypto'} />
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column card-right-content ">
                                            <div className="card-top-sec">
                                                <div className="card-heading">
                                                    <h4 className="text-truncate">{game?.name}</h4>
                                                </div>
                                                <div className="ft-common--text">
                                                    <p>{game?.tagLine}</p>
                                                </div>
                                            </div>
                                            <div className="game-feature-s-btns d-flex justify-content-between">
                                                <div className="d-flex">
                                                    <Link to={`/games-detail/${game?._id}`} className="small-common-btn hover-effect btn btn-playgame">
                                                        <span>Play</span>
                                                    </Link>
                                                </div>
                                                <div className="android-apple-btns d-flex justify-content-center align-items-center">
                                                    {(game.platformType == 1 || game.platformType == 3) && game.androidPackageName ?
                                                        <img src={Images.androidstore} className="img-fluid" alt="img" />
                                                        : ""
                                                    }
                                                    {game.iOSStoreLink}
                                                    {(game.platformType == 2 || game.platformType == 3) && game.iosAppStoreURL ?
                                                        <img src={Images.applestore} className="img-fluid ms-3" alt="img" /> : ""
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                )
                            })}
                        </Slider>
                        : 'No Game Found'}
                </div>
            }
        </div>
    );
}

const mapStateToProps = state => ({
    game: state.game,
    error: state.error,
});

export default connect(mapStateToProps, { beforeGames, getFeaturedGames })(FeaturedGameSlider);