import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Lottie from "react-lottie-player";
import { Link } from "react-router-dom";
import partnershiplottieJson from "../../assets/72333.json";
import "./AboutUs.css";

function AboutDeskillzPartnership() {
    return (
        <div className="deskillz-partnership top-bottom-same-padding">
            <Container fluid className="custom-container">
                <Row className=" align-items-center">
                    <Col lg="5" className="d-flex justify-content-center">
                        <div className="d-flex">
                            <Lottie loop animationData={partnershiplottieJson} play style={{width: 400, height: 200}} />
                        </div>
                    </Col>
                    <Col lg="7">
                        <div className="program-inner-content">
                            <h2>DeSkillz Partnerships</h2>
                            <p className="paragraph-text">Get in on rapidly-growing decentralized blockchain gaming with Deskillz. We ensure  our 
                             platform promotes both sustainability and value. For investment opportunities, <Link to="/contact"> Contact Us. </Link>
                             </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default AboutDeskillzPartnership; 
