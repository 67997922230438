import React, { useState, useEffect, useRef } from "react";
import GameDetalBanner from "./GameDetalBanner";
import BattleFieldSlider from "./BattleFieldSlider";
import GamesDetailSlider2 from "./GamesDetailSlider2";
import "./GamesDetail.css";
import { beforeGames, getGameDetail  , getAllDepdoyment } from './Game.action'
import { useParams } from 'react-router';
import {connect} from 'react-redux'
import {Images} from "../../assets/asset";
import GamesDetailSkeleton from "./GamesDetailSkeleton";
function GamesDetail(props) {
    const myRef = useRef(null)
    const {gameId} = useParams()
    const [game, setGame] = useState()
    const [gameBuild , setGameBuild] = useState('')
    const [relatedGames, setRelatedGames] = useState([])
    const [loader, setLoader] = useState(false)


    //customStyles
    const customStyles ={ 
        backgroundImage: `url(${game?.backgroundImage && game?.backgroundImage != '' ? game?.backgroundImage : Images.GameDetailBg})` ,
        // backgroundImage: `url(${Images.GameDetailBg})` ,
        backgroundRepeat: 'no-repeat',  
        // backgroundSize: 'cover',
        backgroundPosition: '0% 0%'
     }

    useEffect(() => {
          props.getGameDetail(gameId)
    }, [])
    useEffect(() => {
          props.getGameDetail(gameId)
    }, [gameId])
    
    useEffect(() => {
        if (props.game.getGame) {
          const {game, relatedGames} =props.game
          const callback =(data)=>{
            if(data){
                setGameBuild(data[0])
            }
          }
            props.getAllDepdoyment(game._id ,false , callback )
          setGame(game)
          let otherGames ;
          if(relatedGames){
            otherGames = relatedGames.filter((item)=> {
                if(item._id !== String(gameId)){
                    return item;
                }
            })
          }
          console.log("relatedGames: ",relatedGames)
          console.log("otherGames: ",otherGames)
          setRelatedGames(otherGames)
          props.beforeGames()
          myRef.current.scrollIntoView()
        }
    }, [props.game.getGame])
    const handleScroll = (ref) => {
        window.scrollTo({
          top: ref.offsetTop,
          left: 0,
          behavior: "smooth",
        });
      };
    return (
        <div className="game-detail-pg-wraper">
            <div className="game-detail-bg" style={customStyles} ref={myRef}>
                {
                    game ?
                    <>
                        <GameDetalBanner game={game} gameBuild={gameBuild}/>
                        <BattleFieldSlider images={game?.tutorialImages ? game?.tutorialImages : []}/>
                        {relatedGames.length> 0 && <GamesDetailSlider2 games={relatedGames}/>}
                    </>
                    :
                    <GamesDetailSkeleton></GamesDetailSkeleton>
                }
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    game: state.game,
    error: state.error,
});
export default connect(mapStateToProps, {beforeGames, getGameDetail , getAllDepdoyment})(GamesDetail);
