import { Container, Row, Col } from "react-bootstrap";
import { Images } from "../../../assets/asset";
import Slider from "react-slick";
import "./WhatsComing.css";
import { beforeWhatscoming, getWhatscoming } from './WhatsComing.action'
import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
var settings = {
    dots: false,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    infinite: true,
    loop:true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    prevArrow: <BannerPrevArrow />,
    nextArrow: <BannerNextArrow />,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

function BannerNextArrow(props) {
    const { onClick } = props;
    return (
        <div onClick={onClick} className="sliderRightArrow">
            <span onClick={onClick} className="slick-arrow slick-next" />
        </div>
    );
}
function BannerPrevArrow(props) {
    const { onClick } = props;
    return (
        <div onClick={onClick} className="sliderLeftArrow">
            <span onClick={onClick} className="slick-arrow slick-prev" />
        </div>
    );
}

function WhatsComing(props) {

    const [whatscoming, setWhatscoming] = useState([])

    useEffect(() => {
        if (props.whatscoming.getwhatscoming) {
            const { whatscoming } = props.whatscoming
            setWhatscoming(whatscoming)
        }
    }, [props.whatscoming.getwhatscoming])


    useEffect(() => {
        props.getWhatscoming()
    }, [])


    return (
        <>
            <section className="whats-coming-for-you top-bottom-same-padding">
                <Container fluid className=" custom-container">
                    <Row>
                        <div className="program-inner-content">
                            <h2>What’s Coming!</h2>
                            <p>Dive into the latest games and crypto news.</p>
                        </div>
                    </Row>
                    <Row>
                        <div className="whats-coming-for-you-slider">
                            <Slider {...settings}>

                                {
                                    whatscoming && whatscoming.map((coming) => {
                                        return (

                                            <div className="whatscoming-padding">
                                                <Link to={`/cms/${coming?.slug}`} className="card-contain">
                                                    <div className="whatcoming-img-height">
                                                        <img src={coming?.thumbnail ? coming?.thumbnail  : Images.newsimg1} onError={(e) => { e.target.onerror = null; e.target.src = Images.newsimg1 }} alt="img" className="img-fluid" />
                                                    </div>
                                                    <div className="card-inner-content w-100">
                                                        <h4>{coming?.title ? coming?.title : 'N/A'}</h4>
                                                        <p className="text-danger-custom">by Deskillz on Blog Posts</p>
                                                        <p>{coming?.tagLine ?  coming?.tagLine : 'N/A'}</p>
                                                    </div>
                                                </Link>
                                            </div>

                                        );
                                    })
                                }

                                {/* <div className="card-contain" >
                                    <div className="whatcoming-img-height">
                                        <img src={Images.challange} alt="img" className="img-fluid" />
                                    </div>
                                    <div className="card-inner-content">
                                        <h4>Big Buck Hunter: Marksman mobile game version exclusively on Deskillz’</h4>
                                        <p className="text-danger-custom">by Deskillz on Blog Posts</p>
                                        <p>Play Mechanix, Creator of the Big Buck Hunter: Marksman Mobile Game, Recognized As The Year’s “Best In Class” Developer Honored for Creativity, Innovation, and Growth on Skillz’ Platform Recognizing the talents of its developer community that builds highly competitive game experiences for everyone […]</p>
                                    </div>
                                </div> */}
                                {/* <div className="card-contain">
                                    <img src={Images.bickbuck} alt="img" className="img-fluid" />
                                    <div className="card-inner-content">
                                        <h4>Big Buck Hunter: Marksman mobile game version exclusively on Deskillz’</h4>
                                        <p className="text-danger-custom">by Deskillz on Blog Posts</p>
                                        <p>Play Mechanix, Creator of the Big Buck Hunter: Marksman Mobile Game, Recognized As The Year’s “Best In Class” Developer Honored for Creativity, Innovation, and Growth on Skillz’ Platform Recognizing the talents of its developer community that builds highly competitive game experiences for everyone […]</p>
                                    </div>
                                </div>
                                <div className="card-contain">
                                    <img src={Images.challange} alt="img" className="img-fluid" />
                                    <div className="card-inner-content">
                                        <h4>Big Buck Hunter: Marksman mobile game version exclusively on Deskillz’</h4>
                                        <p className="text-danger-custom">by Deskillz on Blog Posts</p>
                                        <p>Play Mechanix, Creator of the Big Buck Hunter: Marksman Mobile Game, Recognized As The Year’s “Best In Class” Developer Honored for Creativity, Innovation, and Growth on Skillz’ Platform Recognizing the talents of its developer community that builds highly competitive game experiences for everyone […]</p>
                                    </div>
                                </div>
                                <div className="card-contain">
                                    <img src={Images.bickbuck} alt="img" className="img-fluid" />
                                    <div className="card-inner-content">
                                        <h4>Big Buck Hunter: Marksman mobile game version exclusively on Deskillz’</h4>
                                        <p className="text-danger-custom">by Deskillz on Blog Posts</p>
                                        <p>Play Mechanix, Creator of the Big Buck Hunter: Marksman Mobile Game, Recognized As The Year’s “Best In Class” Developer Honored for Creativity, Innovation, and Growth on Skillz’ Platform Recognizing the talents of its developer community that builds highly competitive game experiences for everyone […]</p>
                                    </div>
                                </div>
                                <div className="card-contain">
                                    <img src={Images.challange} alt="img" className="img-fluid" />
                                    <div className="card-inner-content">
                                        <h4>Big Buck Hunter: Marksman mobile game version exclusively on Deskillz’</h4>
                                        <p className="text-danger-custom">by Deskillz on Blog Posts</p>
                                        <p>Play Mechanix, Creator of the Big Buck Hunter: Marksman Mobile Game, Recognized As The Year’s “Best In Class” Developer Honored for Creativity, Innovation, and Growth on Skillz’ Platform Recognizing the talents of its developer community that builds highly competitive game experiences for everyone […]</p>
                                    </div>
                                </div> */}

                            </Slider>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    );
}


const mapStateToProps = state => ({
    whatscoming: state.whatscoming,
    error: state.error,
});

export default connect(mapStateToProps, { beforeWhatscoming, getWhatscoming })(WhatsComing);