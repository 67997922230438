import React, { Fragment } from 'react'
import { Container, Row, Col } from "react-bootstrap";

function GamingPlatform() {
    return (
        <Fragment>
            <section className="start-to-play">
                <Container>
                    <Row>
                        <Col sm='12'>
                            <div className="start-heading mb-3">
                                <h3 className="common-heading text-center text-black">Revolutionizing Crypto Gaming Platform</h3>
                            </div>
                            <p className="paragraph-text-2 text-black mb-3">
                                The Decentralized Gaming Ecosystem is transforming online gaming by leveraging blockchain technology for unmatched security, transparency, and fairness. A Decentralized Platform for Gamers ensures data and asset security in a trustless environment. This Blockchain Gaming Ecosystem allows true ownership of in-game assets, enabling free trade and smart contracts for fair transactions. Players can win cash prizes online through a Decentralized Gaming Portal, combining the benefits of a Crypto Gaming Platform and a Decentralized Online Gaming Platform.
                            </p>
                            <p className="paragraph-text-2 text-black mb-3">
                                This synergy creates a seamless game integration platform, ideal for decentralized tournaments online and mobile gaming tournaments, offering endless competition for cash prizes. The Decentralized Blockchain Gaming Platform supports a creative, low-fee environment for developers. Join the Decentralized Crypto Gaming Ecosystem, participate in decentralized game tournament platforms, and experience an Esports Gaming Tournament that redefines online play with control, ownership, and rewards.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    )
}

export default GamingPlatform