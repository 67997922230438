import React, { Component } from "react";
import "./NotFound.css"
class NotFound extends Component {
  render() {
    return (
      <div className="bg-black vh-100 text-white">
        <div className="develper-banner">
          <div className="custom-container container-fluid">
            <div className="notfound">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <h4 className="subpg-comon-heading">Oops! Page not found</h4>
                <div className="notfound-404">
                  <h1>404</h1>
                </div>
                <h4 className="subpg-comon-heading">we are sorry, but the page you requested was not found</h4>
                <div className="btn-holder d-flex justify-content-center flex-column"><a
                  href="/" className="btn red-btn btn-playgame"><span>HOMEPAGE</span></a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFound;