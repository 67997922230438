import React, {useState, useEffect} from "react";
import {Images} from "../../assets/asset";
import {Link} from "react-router-dom";
import Slider from "react-slick";
import {connect} from 'react-redux'
import { beforeGames, getNewGames } from '../GamesDetail/Game.action'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

var newgamesettings = {
    dots: true,
    arrows: false,
    infinite: false,
    loop:true,
    initialSlide: 0,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2500,
    prevArrow: <BannerPrevArrow />,
    nextArrow: <BannerNextArrow />,
    responsive: [
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                dots:false
            },
        },
    ],
};
function BannerNextArrow(props) {
    const { onClick } = props;
    return (
        <div onClick={onClick} className="sliderRightArrow">
            <span onClick={onClick} className="slick-arrow slick-next" />
        </div>
    );
}
function BannerPrevArrow(props) {
    const { onClick } = props;
    return (
        <div onClick={onClick} className="sliderLeftArrow">
            <span onClick={onClick} className="slick-arrow slick-prev" />
        </div>
    );
}
function NewGameSlider(props) {
    const [games, setGames] = useState([])
    const [newLoading, setNewLoading] = useState(true)
    useEffect(() => {
        setNewLoading(true)
        props.getNewGames()
    }, [])

    useEffect(() => {
        if(props.game.getNewGames){
            setGames(props.game.newGames)
            setNewLoading(false)
        }
    }, [props.game.getNewGames])
    return (
        <div className="new-games-section">
            <div className="d-flex heading-wraper">
                <h3 className="common-heading">New Games</h3>
            </div>
            {newLoading 
            ?
            <div className="game-featured-slider common-dot-sliderbtn feature-games-slider">
                <Slider {...newgamesettings}>
                    {
                        [0,1,2,3,4].map((seketon, index)=>{
                            return(
                                <div className="most-popular-card" key={index}>
                                        <div className="most-popular-image-container">
                                            {/* <Skeleton circle={true} width={100} height={100} /> */}
                                        </div>
                                        <div className="bottom-profile-content d-flex">
                                            <div className="profile-info d-flex flex-column justify-content-center">
                                                <SkeletonTheme color="#202020" highlightColor="#d31d05">
                                                    <Skeleton count={2}  />
                                                    <Skeleton count={2}  />
                                                </SkeletonTheme>
                                            </div>
                                        </div>
                                    </div>
                            )
                        })
                    }
                </Slider>
            </div>
            :
            <div className="most-popular-slider common-dot-sliderbtn feature-games-slider">
                <Slider {...newgamesettings}>
                    {games && games.length > 0 ? 
                        games.slice(0, 6).map((game, index) => {
                            return(
                                <div className="new-games-card">
                                    <div className="new-games-image-container">
                                     <div className="card-img-holder">
                                     <img src={game?.backgroundImage ? game?.backgroundImage: Images.newgamesimg1} className="img-fluid" alt="img" />
                                     </div>
                                    </div>
                                    <div className="new-games-bottom-content d-flex justify-content-between align-items-center">
                                        <div className="d-flex mb-sm-0 mb-3">
                                            <div className="new-games-img-warper d-flex">
                                                <img src={game?.icon ? game?.icon : Images.zumbie} className="img-fluid slider-img-playersite" alt="img" />
                                            </div>
                                            <div className="profile-info d-flex flex-column justify-content-center">
                                                <h5>{game?.name}</h5>
                                                <p>{game?.tagLine}</p>
                                            </div>
                                        </div>
                                        <Link to={`/games-detail/${game?._id}`} className="small-common-btn hover-effect btn btn-playgame">
                                            <span>Play</span>
                                        </Link>
                                    </div>
                                </div>
                            )
                        }):'' 
                    }
                </Slider>
            </div>
            }
        </div>
    );
}

const mapStateToProps = state => ({
    game: state.game,
    error: state.error,
});

export default connect(mapStateToProps, {beforeGames, getNewGames})(NewGameSlider);
