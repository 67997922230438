import { toast } from 'react-toastify';
import axios from 'axios'
import { GET_ERRORS, BEFORE_FAQS, GET_FAQS} from '../../redux/types';
import { emptyError } from '../../redux/shared/error/error.action';
import { ENV } from '../../config/config';

export const beforeFaqs = () => {
    return {
        type: BEFORE_FAQS
    }
}

export const getAllFaqs = (body) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}faq/get`;
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data.success) {
            // toast.success(data.message)
            dispatch({
                type: GET_FAQS,
                payload: data.faqs
            })
        } else {
            // toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data.data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            // if (data.message)
                // toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};



