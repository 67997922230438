import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Images } from "../../assets/asset";
import { beforeGames, getGenres } from '../GamesDetail/Game.action'
import { connect } from 'react-redux'
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import { ENV } from '../../config/config'
import Pagination from "rc-pagination";
import localeInfo from 'rc-pagination/lib/locale/en_US';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "rc-pagination/assets/index.css";
import "./ExploreGenres.css";


function ExploreGenres(props) {

	const [loader, setLoader] = useState(true)
	const [genres, setGenres] = useState([])
	const [pagination, setPagination] = useState({})
	const [Page, setPage] = useState(1)

	useEffect(() => {
		props.getGenres()
	}, [])

	useEffect(() => {
		if (props.game.getGenresAuth) {
			setGenres(props.game.genres.genres)
			setPagination(props.game.genres.pagination)
			setLoader(false)
			props.beforeGames()
		}
	}, [props.game.getGenresAuth])

	const onPageChange = async (page) => {
		setLoader(true)
		setPage(page)
		const qs = ENV.objectToQueryString({ page, limit: 8 })
		props.getGenres(qs)
	}

	return (
		<div className="feature-pg all-genres-listing">
			{
				loader ?
					<FullPageLoader />
					:
					<Container fluid className="custom-container">
						<div className="d-flex mb-3 mb-md-5">
                            <Link to="/explore-games" className="back-btn">
                                <FontAwesomeIcon icon={faArrowLeft} className="me-3" />
                                Back
                            </Link>
                        </div>
						<div className="mb-4 mb-md-5 d-flex justify-content-between align-items-center flex-column flex-md-row">
							<h1 className="section-heading mb-3 mb-md-0">Game Genres List</h1>
							<form className="page-search-form position-relative">
								<div className="input-holder">
									<input type="text" className="form-ccontrol position-relative" placeholder="Search" />
								</div>
								<span className="icon-search">
									<FontAwesomeIcon icon={faSearch} />
								</span>
							</form>
						</div>
						<Row>
							{
								genres && genres.length ?
									genres.map((genre, index) => {
										return (
											<Col xl={3} lg={4} md={6} key={index}>
												<div class="explore-games-card" tabindex="-1">
													<div class="explore-games-image-container">
														<img src={genre?.icon ? genre?.icon : Images.eaploregamesimg1} className="img-fluid" alt="img" />
													</div>
													<div class="explore-games-bottom-content profile-info">
														<h5 class="black-text text-center">
															<a href={`/genre-games/${genre?._id}`}>
																{genre?.name}
															</a>
														</h5>
													</div>
												</div>
											</Col>
										)
									})
									: ''
							}
						</Row>
						<>
							{
								genres && genres.length ? <Pagination
									className="m-3"
									defaultCurrent={1}
									pageSize // items per page
									current={Page > pagination?.pages ? pagination.pages : Page} // current active page
									total={pagination?.pages ? pagination.pages : Page} // total pages
									onChange={onPageChange}
									locale={localeInfo}
								/>
									: ''
							}
						</>
					</Container>
			}
		</div>
	);
}

const mapStateToProps = state => ({
	game: state.game,
	error: state.error,
});

export default connect(mapStateToProps, { beforeGames, getGenres })(ExploreGenres);