
import {BEFORE_GAME, GET_GAMES, GET_GAME, GET_FEATURED_GAMES, GET_POPULAR_GAMES, GET_NEW_GAMES, GET_GENRES, GET_GENRE_GAMES , GET_GENRE } from '../../redux/types'
const initialState = { 
    game:null, 
    relatedGames:null, 
    getGame:false, 
    getGames:false, 
    featuredGames:null, 
    getFeaturedGames:false ,
    popularGames:null, 
    getPopularGames:false, 
    newGames:null, 
    getNewGames:false, 
    genres:null, 
    getGenresAuth:false, 
    games:null, 
    getGenreGamesAuth:false,
    genre:null,
    getGenreAuth:false, 
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_GAME:
            return { ...state, game: action.payload.game, relatedGames: action.payload.relatedGames, getGame: true }
        case GET_GENRE:
            return{ ...state , genre: action.payload , getGenreAuth:true }
        case GET_FEATURED_GAMES:
            return { ...state, featuredGames: action.payload, getFeaturedGames: true }
        
        case GET_NEW_GAMES:
            return { ...state, newGames: action.payload, getNewGames: true }
        
        case GET_POPULAR_GAMES:
            return { ...state, popularGames: action.payload, getPopularGames: true }
        
        case GET_GENRES:
            return { ...state, genres: action.payload, getGenresAuth: true }
        
        case GET_GENRE_GAMES:
            return { ...state, games: action.payload, getGenreGamesAuth: true }
        case GET_GAMES:
            return { ...state, games: action.payload, getGames: true }
       
        case BEFORE_GAME:
            return { ...state, getGame:false, getFeaturedGames:false, getNewGames: false, getGenreGamesAuth: false, getGames: false, getGenresAuth: false }
            
        default:
            return { ...state }
    }
}