import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import {Images} from "../../assets/asset";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { ENV } from "./../../config/config";
import "./Developers.css";

function GamesFeature() {
    return (
        <div>
            <div className="game-feature-bg top-bottom-same-padding ">
                <Container fluid className="custom-container">
                    <Row className="jusify-content-center align-items-center">
                        <Col lg="6">
                            <div className="program-inner-content">
                                <h2>Take Your Game to the Next Level</h2>
                                {/* <p>Sed a magna semper, porta purus eu, ullamcorper ligula. Nam sit amet consectetur sapien. Etiam dui ipsum, viverra vel turpis ut, dignissim elementum mauris. Sed dapibus auctor scelerisque.</p> */}
                               <span>Integrate your game into our ecosystem. <a href={`${ENV.deskillzDeveloperSiteUrl}/login`}> Get started!</a></span>
                                {/* <ul className="list-info">
                                    <li>
                                        <div className="d-flex align-items-center">
                                            <FontAwesomeIcon icon={faCheck} className="me-3" />
                                            Aenean at leo tellus. Morbi eu leo sapien. Fusce libero dolor
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex align-items-center">
                                            <FontAwesomeIcon icon={faCheck} className="me-3" />
                                            venenatis eget enim sed, commodo efficitur arcunmyu
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex align-items-center">
                                            <FontAwesomeIcon icon={faCheck} className="me-3" />
                                            Sed et nisi id diam condimentum porttitor
                                        </div>
                                    </li>
                                </ul> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* <div className="how-it-works top-bottom-same-padding">
                <Container fluid className="custom-container">
                    <Row className="jusify-content-center align-items-center">
                        <Col lg="5">
                            <div className="d-flex">
                                <img src={Images.groupHelp} className="img-fluid" alt="img" />
                            </div>
                        </Col>
                        <Col lg="7">
                            <div className="lets-help-succeed program-inner-content mt-lg-0 mt-5">
                                <h2>Let’s us help you succeed</h2>
                                <p>Sed a magna semper, porta purus eu, ullamcorper ligula. Nam sit amet consectetur sapien. Etiam dui ipsum, viverra vel turpis ut, dignissim elementum mauris. Sed dapibus auctor scelerisque.</p>
                                <ul className="list-info">
                                    <li>
                                        <div className="d-flex align-items-center">
                                            <img src={Images.tick} className="img-fluid" alt="img" />
                                            Aenean at leo tellus. Morbi eu leo sapien. Fusce libero dolor
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex align-items-center">
                                            <img src={Images.tick} className="img-fluid" alt="img" />
                                            venenatis eget enim sed, commodo efficitur arcunmyu
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex align-items-center">
                                            <img src={Images.tick} className="img-fluid" alt="img" />
                                            Sed et nisi id diam condimentum porttitor
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div> */}
        </div>
    );
}

export default GamesFeature;
