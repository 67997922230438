import React, { useState, useEffect }  from "react";
import {Images} from "../../assets/asset";
import Slider from "react-slick";
import {connect} from 'react-redux'
import { beforeGames, getGenres } from '../GamesDetail/Game.action'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import {Link} from "react-router-dom";
var exploregamesettings = {
    dots: true,
    arrows: false,
    infinite: false,
    loop:true,
    initialSlide: 0,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2500,
    prevArrow: <BannerPrevArrow />,
    nextArrow: <BannerNextArrow />,

    responsive: [
        {
            breakpoint: 1399,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
            },
        },
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
            },
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
            },
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                dots:false
            },
        },
    ],
};
function BannerNextArrow(props) {
    const { onClick } = props;
    return (
        <div onClick={onClick} className="sliderRightArrow">
            <span onClick={onClick} className="slick-arrow slick-next" />
        </div>
    );
}
function BannerPrevArrow(props) {
    const { onClick } = props;
    return (
        <div onClick={onClick} className="sliderLeftArrow">
            <span onClick={onClick} className="slick-arrow slick-prev" />
        </div>
    );
}
function ExploreGamesSlider(props) {
    const [genres, setGenres] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        props.getGenres()
    }, [])
    
    useEffect(() => {
        if(props.game.getGenresAuth){
            setGenres(props.game.genres.genres)
            setLoading(false)
            props.beforeGames()
        }
    }, [props.game.getGenresAuth])

    return (
        <div className="explore-games">
            <div className="d-flex heading-wraper">
                <h3 className="common-heading">
                    Explore Genres 
                    <Link to="/explore-genres" className="explore-more ms-2">Explore More</Link>
                </h3>
            </div>
            {loading 
            ?
            <div className="game-featured-slider common-dot-sliderbtn feature-games-slider">
                <Slider {...exploregamesettings}>
                    {
                        [0,1,2,3].map((seketon, index)=>{
                            return(
                                <div className="most-popular-card" key={index}>
                                        <div className="most-popular-image-container">
                                            {/* <Skeleton circle={true} width={100} height={100} /> */}
                                        </div>
                                        <div className="bottom-profile-content d-flex">
                                            <div className="profile-info d-flex flex-column justify-content-center">
                                                <SkeletonTheme color="#202020" highlightColor="#d31d05">
                                                    <Skeleton count={2} enableAnimation={true}  />
                                                    <Skeleton count={2}  />
                                                </SkeletonTheme>
                                            </div>
                                        </div>
                                    </div>
                            )
                        })
                    }
                </Slider>
            </div>
            :
            <div className="explore-games-slider common-dot-sliderbtn feature-games-slider">
                <Slider {...exploregamesettings}>
                    {genres && genres.length > 0 ?
                    genres.map((genre, index) => {
                        return (
                            <div className="explore-games-card" key={index}>
                                <div className="explore-games-image-container">
                                    <img src={genre?.icon ? genre?.icon : Images.eaploregamesimg1} className="img-fluid" alt="img" />
                                </div>
                                <div className="explore-games-bottom-content profile-info">
                                    <h5 className="black-text text-center">
                                        <a href={`/genre-games/${genre?._id}`}>
                                            {genre?.name}
                                        </a>
                                    </h5>
                                </div>
                            </div>

                        )
                    }) : ''
                    }
                   
                </Slider>
            </div>
            }
        </div>
    );
}

const mapStateToProps = state => ({
    game: state.game,
    error: state.error,
});

export default connect(mapStateToProps, {beforeGames, getGenres})(ExploreGamesSlider);
