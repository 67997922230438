
import {BEFORE_FRONT_NEW,GET_FRONT_NEW, GET_NEWS} from '../../redux/types'
const initialState = { 
    news:null,
    oneNews:null,
    pagination:null,  
    getnews:false ,
    getNewAuth:false
}

const LatestNewsReducer =(state = initialState, action)=>{
    switch (action.type) {
        case GET_FRONT_NEW:
            return {...state, news: action.payload.news, pagination:action.payload.pagination, getnews: true }
        case GET_NEWS:
            return {...state, oneNews: action.payload, getNewAuth: true }
        case BEFORE_FRONT_NEW:
            return {...state, getnews:false , getNewAuth:false }
        default:
            return {...state }
    }
}
export default LatestNewsReducer;