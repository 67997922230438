import siteLogo from "./images/site-logo.png";
import LogoWhite from "./images/logo-white.png";
import wallet from "./images/wallet.png";
import spidergirl from "./images/spidergirl.png";
import spiderman from "./images/spiderman.png";
import awardmedal from "./images/awardmedal.svg";
import handshake from "./images/handshake.svg";
import settingtoo from "./images/settingtoo.svg";
import target from "./images/target.svg";
import gamingdashboard from "./images/gaming-dashboard.png";
import loyalitypg from "./images/loyality-pg.svg";
import tick from "./images/tick.svg";
import readyplay from "./images/readyplay.png";
import readysliderimg1 from "./images/ready-slider-img1.png";
import readysliderimg2 from "./images/ready-slider-img2.png";
import readysliderimg3 from "./images/ready-slider-img3.png";
import smalltick from "./images/small-tick.svg";
import bgready from "./images/slider-bg-ready.png";
import challange from "./images/challange.png";
import bickbuck from "./images/bickbuck.png";
import soldier1 from "./images/soldier2.png";
import applestore from "./images/applestore.png";
import androidstore from "./images/androidstore.png";
import mostpopularimg1 from "./images/most-popular-img1.png";
import mostpopularimg2 from "./images/most-popular-img2.png";
import mostpopularimg3 from "./images/most-popular-img3.png";
import mostpopularimg4 from "./images/most-popular-img4.png";
import subway from "./images/subway.png";
import profilepopularimg1 from "./images/popular-profile-img1.png";
import profilepopularimg2 from "./images/popular-profile-img2.png";
import profilepopularimg3 from "./images/popular-profile-img3.png";
import newgamesimg1 from "./images/new-games-img-1.png";
import newgamesimg2 from "./images/new-games-img-2.png";
import chivalry from "./images/chivalry.png";
import zumbie from "./images/zumbie.png";
import eaploregamesimg1 from "./images/eaploregames-img1.png";
import eaploregamesimg2 from "./images/eaploregames-img2.png";
import eaploregamesimg3 from "./images/eaploregames-img3.png";
import eaploregamesimg4 from "./images/eaploregames-img4.png";
import eaploregamesimg5 from "./images/eaploregames-img5.png";
import eaploregamesimg6 from "./images/eaploregames-img6.png";
import iconGrow from "./images/icon-grow.svg";
import iconSupport from "./images/icon-support.svg";
import iconIntegrate from "./images/icon-integrate.svg";
import serviceglobal from "./images/service-global.svg"
import servicememory from "./images/service-memory.svg";
import videohowGetPaid from "./images/videohow-get-paid.png";
import videohowitworks from "./images/videohow-it-works.png";
import footerlogo from "./images/footer-logo.png";
import Login from "./images/login.png";
import Dashboard from "./images/dashboard.svg";
import Game from "./images/game.svg";
import Search from "./images/search.svg";
import Statements from "./images/statements.svg";
import Document from "./images/document.svg";
import Download from "./images/download.svg";
import Help from "./images/help.svg";
import Account from "./images/account.svg";
import Video from "./images/video.png";
import Video2 from "./images/video2.png";
import User2 from "./images/user-2.svg";
import Call from "./images/call.svg";
import Globe from "./images/globe.svg";
import Game1 from "./images/game1.png";
import Game2 from "./images/game2.png";
import Game3 from "./images/game3.png";
import Dots from "./images/dots.svg";
import upload from "./images/upload.png";
import playersvideoposter from "./images/players-videoposter.png";
import Handsofdating from "./images/Handsofdating.png";
import competition from "./images/competition.svg";
import appleStore from "./images/apple-store.png";
import googlePlay from "./images/google-play.png";
import unity from "./images/unity.svg";
import battelfield from "./images/battelfield.png";
import myPhone from "./images/myPhone.png";
import myPhoneLandscape from "./images/myPhoneLandscape.png";
import myLaptop from "./images/myLaptop.png";
import Metamask from "./images/metamask.png";
import battlefield1 from "./images/battlefield1.png";
import battlefield2 from "./images/battlefield2.png";
import battlefield3 from "./images/battlefield3.png";
import gameDetail1 from "./images/gameDetail1.png";
import gameDetail2 from "./images/gameDetail2.png";
import gameDetail3 from "./images/gameDetail3.png";
import gameDetail4 from "./images/gameDetail4.png";
import blackApple from "./images/blackApple.png";
import blackAndroid from "./images/blackAndroid.png";
import qrcode from "./images/qrcode.png";
import groupHelp from "./images/groupHelp.png";
import aboutburn from "./images/about-burn.png";
import aboutvegasgame from "./images/about-vegas-game.png";
import aboutbigtime from "./images/aboutbig-time.png";
import aboutchallange from "./images/about-challange.png";
import abouthub from "./images/about-hub.svg";
import abouthelp from "./images/about-help.svg";
import aboutfingerprint from "./images/about-fingerprint.svg";
import aboutaward from "./images/about-award.svg";
import abouarrow from "./images/abou-arrow.svg";
import abothut from "./images/abot-hut.svg";
import newsimg1 from "./images/news-img1.png";
import newsimg2 from "./images/news-img2.png";
import newsimg3 from "./images/news-img3.png";
import newsimg4 from "./images/news-img4.png";
import newsimg5 from "./images/news-img5.png";
import newsimg6 from "./images/news-img6.png";
import favicon from "./images/favicon.png";
import playerSignup from "./images/players-signup.png";
import playerLogin from "./images/players-login.png";
import distribute from "./images/distribute.svg";
import gamecontroller from "./images/gamecontroller.svg";
import privacy from "./images/privacy.svg";
import protect from "./images/protect.svg";
import GameFi from "./images/gamecontroller-color.svg";
import Security from "./images/protect-color.svg";
import AboutPrivacy from "./images/privacy-color.svg";
import Decentralized from "./images/distribute-color.svg";
import GameDetailBg from "./images/game-detail-bg.png";
import dskillzLogo from "./images/d-logo.png"

export const Images = {
    playerSignup,
    playerLogin,
    siteLogo,
    LogoWhite,
    spidergirl,
    spiderman,
    wallet,
    awardmedal,
    handshake,
    settingtoo,
    target,
    gamingdashboard,
    loyalitypg,
    tick,
    footerlogo,
    readysliderimg1,
    readysliderimg2,
    readysliderimg3,
    readyplay,
    smalltick,
    bgready,
    challange,
    bickbuck,
    Login,
    soldier1,
    applestore,
    androidstore,
    mostpopularimg1,
    mostpopularimg2,
    mostpopularimg3,
    mostpopularimg4,
    profilepopularimg1,
    profilepopularimg2,
    profilepopularimg3,
    newgamesimg1,
    newgamesimg2,
    Dashboard,
    Game,
    Statements,
    Document,
    Download,
    Help,
    Account,
    Video,
    Video2,
    User2,
    Call,
    Globe,
    Game1,
    Game2,
    Game3,
    Dots,
    subway,
    eaploregamesimg1,
    eaploregamesimg2,
    eaploregamesimg3,
    eaploregamesimg4,
    eaploregamesimg5,
    eaploregamesimg6,
    chivalry,
    zumbie,
    upload,
    iconGrow,
    iconSupport,
    iconIntegrate,
    serviceglobal,
    servicememory,
    videohowGetPaid,
    videohowitworks,
    playersvideoposter,
    Handsofdating,
    competition,
    Handsofdating,
    battelfield,
    appleStore,
    googlePlay,
    myPhone,
    myPhoneLandscape,
    myLaptop,
    Search,
    Metamask,
    battlefield1,
    battlefield2,
    battlefield3,
    gameDetail1,
    gameDetail2,
    gameDetail3,
    gameDetail4,
    blackAndroid,
    blackApple,
    qrcode,
    groupHelp,
    unity,
    aboutvegasgame,
    aboutbigtime,
    aboutburn,
    aboutchallange,
    abouthub,
    abouthelp,
    aboutfingerprint,
    aboutaward,
    abouarrow,
    abothut,
    newsimg1,
    newsimg2,
    newsimg3,
    newsimg4,
    newsimg5,
    newsimg6,
    favicon,
    distribute,
    gamecontroller,
    privacy,
    protect,
    GameFi,
    Security,
    AboutPrivacy,
    Decentralized,
    GameDetailBg,
    dskillzLogo
};
