const axios= require('axios')
module.exports = {
    ENV: {
        url: process.env.REACT_APP_BASE_URL,
        deskillzMainSiteUrl: process.env.REACT_APP_DESKILLZ_MAIN_SITE,
        deskillzPlayerSiteUrl: process.env.REACT_APP_DESKILLZ_PLAYER_SITE,
        deskillzDeveloperSiteUrl: process.env.REACT_APP_DESKILLZ_DEVELOPER_SITE,
        playStoreBundleUrl: process.env.REACT_APP_PLAYSTORE_BUNDLE_URL,

        // Headers
        Authorization: `Bearer ${process.env.REACT_APP_AUTHORIZATION}`,
        x_auth_token: process.env.REACT_APP_X_AUTH_TOKEN,

        uploadedImgPath: `${process.env.REACT_APP_ASSETS_BASE_URL}images/`,
        objectToQueryString: function (body) {
            const qs = Object.keys(body).map(key => `${key}=${body[key]}`).join('&');
            return qs;
        },
        uploadImage:function (e) {
            let data = new FormData();
            data.append('image', e.target.files[0]);
            return new Promise((resolve ,reject)=>{
                axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_BASE_URL}users/upload-image`,
                    data: data,
                    headers: {'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${process.env.REACT_APP_AUTHORIZATION}`,
                            'x-auth-token': process.env.REACT_APP_X_AUTH_TOKEN}
                    })
                    .then(data => {
                        resolve(data.data.data)
                    })
                    .catch(err => {
                        reject({err})
                    })
                }) 
        },
        
    }
}