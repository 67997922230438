import { toast } from 'react-toastify';
import axios from 'axios'
import {BEFORE_CONTACT_US,GET_CONTACT_US, GET_ERRORS} from '../../redux/types';
import { emptyError } from '../../redux/shared/error/error.action';
import { ENV } from '../../config/config';

export const beforeContacts = () => {
    return {
        type: BEFORE_CONTACT_US
    }
}

export const getAllContact = (body) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}settings/get-contact-us`;
    fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data.success) {
            // toast.success(data.message)
            dispatch({
                type: GET_CONTACT_US,
                payload: data.settings
            })
        } 
        else {
            // toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            // if (data.message)
                // toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};



